import React from 'react'
import Logo1 from './images/placeholder Fill.png'
import Logo2 from './images/placeholder Fill (1).png'
import LogoLocation from './images/placeholder Fill (2).png'
import LogoLocationActive from './images/placeholder Fill (3).png'
import Nodata from '../../../../images/nodata.png'

import { Cell, Pie, PieChart } from 'recharts'
import moment from 'moment'
const COLORSPINK = ['#1D3557', '#DBE9FF'];

const Kyc = ({ addressDataApi, checkForPep, viewAmlReport, amlData, matchedData, matchedState, amlState, MatchFace, jsondata, locationHandler, selectData, sideData, saveUnderWritter, underWriterHandler, renderCustomizedLabelLocation, chartDataStateLocation, openCommentPopup, locationNotes, panNotes, allDetail, karzaData, kycTabHandle, panState, locationState, renderCustomizedLabel, kycSubStateAAdhaar, chartDataState, aadhaarXmlData, aadhaarNotes, commentChangeHandler, admin, saveNotesType, personalData, downloadFile }) => (
    <>

        <div className='col-sm-12 col-xs-12 details-body-credit full-height credit-module' style={{ minHeight: '600px' }}>
            <div className='row' >
                <div className='col-sm-3 col-xs-12' >
                    <div className='row' style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <div className='col-sm-4 col-xs-2 text-center'>
                            <img src={kycSubStateAAdhaar ? Logo1 : Logo2} style={{ width: '32px' }} />
                            <div className='dashed-border-blue'></div>
                            <img src={panState ? Logo1 : Logo2} style={{ width: '32px' }} />
                            <div className='dashed-border-blue'></div>
                            <img src={locationState ? LogoLocationActive : LogoLocation} style={{ width: '32px' }} />
                            <div className='dashed-border-blue'></div>
                            <img src={amlState ? LogoLocationActive : LogoLocation} style={{ width: '32px' }} />
                            <div className='dashed-border-blue'></div>
                            <img src={matchedState ? Logo1 : Logo2} style={{ width: '32px' }} />
                        </div>
                        <div className='col-sm-8 col-xs-10 '>
                            <div className='step-aadhar' onClick={() => kycTabHandle('aadhar')}>
                                <h5 style={{ color: kycSubStateAAdhaar ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: kycSubStateAAdhaar ? '700' : '400' }}>Aadhaar Number</h5>
                                <p style={{ color: '#00C851', fontSize: '11px', fontWeight: '400', marginTop: '10px' }}> {sideData && sideData.aadharVerfiedKarza ? <div className="green-bg-kyc">
                                    {sideData.aadharDecision}</div> :
                                    <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                        {sideData.aadharDecision}</div>
                                }   </p>
                                {sideData && sideData.aadharXmlDetail !== '' && sideData.aadharXmlDetail !== null && sideData.aadharXmlDetail !== undefined ?
                                    <p style={{ color: 'rgba(0, 0, 0, 0.40)', fontSize: '10px', fontWeight: '400' }}>Verified on - {moment(sideData.aadharXmlDetail.date).format('DD-MM-YYYY')}</p>
                                    : ""}
                            </div>
                            <div className='step-aadhar' onClick={() => kycTabHandle('pan')} style={{ marginTop: sideData.aadharDecision ? '40px' : '100px' }}>
                                <h5 style={{ color: panState ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: panState ? '700' : '400' }}>PAN Verification</h5>
                                <p style={{ color: '#00C851', fontSize: '11px', fontWeight: '400', marginTop: '10px' }}>   {sideData && sideData.panVerifiedKarza ? <div className="green-bg-kyc">
                                    {sideData.panDecision}</div> :
                                    <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                        {sideData.panDecision}</div>
                                }</p>
                                {sideData && sideData.panProfileDetail
                                    !== '' && sideData.panProfileDetail
                                    !== null && sideData.panProfileDetail
                                    !== undefined ?
                                    <p style={{ color: 'rgba(0, 0, 0, 0.40)', fontSize: '10px', fontWeight: '400' }}>Verified on - {moment(sideData.panProfileDetail.addedOn).format('DD-MM-YYYY')}</p>
                                    : ""}
                            </div>
                            <div className='step-aadhar' onClick={() => kycTabHandle('location')} style={{ marginTop: window.innerWidth > 1900 ? '50px' : sideData.panDecision ? '50px' : '80px' }}>
                                <h5 style={{ color: locationState ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: locationState ? '700' : '400' }}>Location Verification</h5>
                                <p style={{ color: '#00C851', fontSize: '11px', fontWeight: '400', marginTop: '10px' }}>   {sideData && sideData.locationMatchedAadhar ? <div className="green-bg-kyc">
                                    {sideData.locationDecision}</div> :
                                    <div className="green-bg-kyc" style={{ color: '#FF5C5D' }}>
                                        {sideData.locationDecision}</div>
                                }</p>
                                {/* <p style={{ color: 'rgba(0, 0, 0, 0.40)', fontSize: '10px', fontWeight: '400' }}>Verified on - 20/09/2023</p> */}
                            </div>
                            <div className='step-aadhar' onClick={() => kycTabHandle('aml')} style={{ marginTop: window.innerWidth > 1900 ? '70px' : '70px' }}>
                                <h5 style={{ color: amlState ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: amlState ? '700' : '400' }}>AML/ PEP Exposed</h5>

                            </div>

                            <div className='step-aadhar' onClick={() => kycTabHandle('face')} style={{ marginTop: '100px' }}>
                                <h5 style={{ color: matchedState ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: matchedState ? '700' : '400' }}>Face Match</h5>

                            </div>

                        </div>
                    </div>
                </div>
                <div className='col-sm-9 col-xs-12' style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.25)', marginBottom: '20px', minHeight: '600px' }} >

                    {kycSubStateAAdhaar ?
                        <>
                            <div className='row'>
                                <div className='col-sm-6 col-xs-6'>
                                    <h5 style={{ marginTop: '40px' }}><b>Aadhaar Card Information</b></h5>
                                </div>
                                <div className='col-sm-6 col-xs-6'>
                                    {admin.emailId !== 'SuperUser' ?
                                        <>
                                            <div style={{ float: 'inline-end' }} >
                                                <span onClick={() => openCommentPopup('aadhaar')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>
                                                {allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110' ?
                                                    <>
                                                        <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'aadhaar')} >
                                                            <option value={''}>Select Option</option>
                                                            <option value={'Verified By Karza'}>Verified By Karza</option>
                                                            <option value={'Verified By Underwriter'}>Verified By Underwriter</option>
                                                            <option value={'Unverified by Karza'}>Unverified by Karza</option>
                                                            <option value={'Unverified by Underwriter'}>Unverified by Underwriter</option>



                                                        </select>

                                                        <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter('aadharDecision')}>Save</button>
                                                    </> : ""}
                                            </div>                                        </>
                                        : ""}

                                    {admin.emailId === 'SuperUser' ?
                                        <>

                                            <div style={{ float: 'inline-end' }} >
                                                <span onClick={() => openCommentPopup('aadhaar')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>

                                                <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'aadhaar')} >
                                                    <option value={''}>Select Option</option>
                                                    <option value={'Verified By Karza'}>Verified By Karza</option>
                                                    <option value={'Verified By Underwriter'}>Verified By Underwriter</option>
                                                    <option value={'Unverified by Karza'}>Unverified by Karza</option>
                                                    <option value={'Unverified by Underwriter'}>Unverified by Underwriter</option>



                                                </select>

                                                <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter('aadharDecision')}>Save</button>
                                            </div>
                                        </>
                                        : ""}

                                </div>
                            </div>
                            <div className='kyc-card-credit' style={{ marginBottom: '20px' }}>
                                <div className='' style={{ display: 'flex' }} >

                                    <div className='' style={{ borderBottom: '1px solid #A8C8FF', borderRight: '1px solid #A8C8FF', minHeight: '125px', background: jsondata['aadhaar'] && jsondata['aadhaar'].nameMatch ? 'lightgreen' : 'white', borderTopLeftRadius: '10px', width: '50%', marginLeft: '4px' }}>
                                        <div style={{ marginTop: '10px' }}>
                                            <div className='' style={{ cursor: 'pointer' }} onClick={() => selectData('aadhaar', 'nameMatch', chartDataState[0].value)}>
                                                <div className='col-sm-8'>
                                                    <div style={{ marginLeft: '10px', marginTop: '20px' }}> <b>Name Match Percentage</b><br />
                                                        <span style={{ fontSize: '12px' }}>(Input name to Aadhaar Name)</span>
                                                    </div>
                                                </div>
                                                <div className='col-sm-4 remove-rechart-line'>
                                                    {chartDataState.length > 0 ?
                                                        <PieChart width={100} height={100}>
                                                            <Pie
                                                                data={chartDataState}
                                                                cx={50}
                                                                cy={45}
                                                                innerRadius={30}
                                                                outerRadius={40}
                                                                startAngle={450}
                                                                endAngle={90}
                                                                // fill="#1D3557"
                                                                paddingAngle={1}
                                                                dataKey="value"
                                                                label={renderCustomizedLabel}
                                                            >
                                                                {chartDataState.map((entry, index) => (
                                                                    <Cell radius={[5, 5, 5, 5]} key={`cell-${index}`} fill={COLORSPINK[index % COLORSPINK.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>

                                                        : "No Data Found"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='' style={{ borderBottom: '1px solid #A8C8FF', minHeight: '125px', background: jsondata['aadhaar'] && jsondata['aadhaar'].image ? 'lightgreen' : 'white', borderTopRightRadius: '10px', width: '50%', marginRight: '4px' }}>

                                        <div className='' style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => selectData('aadhaar', 'image', aadhaarXmlData.image)}>
                                            <div className='col-sm-6 col-xs-6'>
                                                <div style={{ marginTop: '30px', marginLeft: '10px' }}><b>Photo</b></div>
                                            </div>
                                            <div className='col-sm-6 col-xs-6 text-right'>
                                                {aadhaarXmlData && aadhaarXmlData.image ? <img width='80px' style={{ borderRadius: '5px' }} src={'data:image/png;base64,' + aadhaarXmlData.image} /> : ""}
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <table className="opertion-table-credit"  >
                                    <tr style={{ marginTop: '10px', background: jsondata['aadhaar'] && jsondata['aadhaar'].nameOnAadhaar ? 'lightgreen' : 'white', cursor: 'pointer', borderTopLeftRadius: '10px' }} onClick={() => selectData('aadhaar', 'nameOnAadhaar', aadhaarXmlData.name)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Name on Aadhar</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {aadhaarXmlData ? aadhaarXmlData.name ? <span style={{ fontSize: '14px' }}>{aadhaarXmlData ? aadhaarXmlData.name : ""}</span> : 'No Data Found' : ''}
                                        </td>
                                    </tr>
                                    <tr style={{ marginTop: '10px', background: jsondata['aadhaar'] && jsondata['aadhaar'].fatherName ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aadhaar', 'fatherName', aadhaarXmlData.fatherName)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Father Name</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {aadhaarXmlData ? aadhaarXmlData.fatherName ? <span style={{ fontSize: '14px' }}>{aadhaarXmlData ? aadhaarXmlData.fatherName : ""}</span> : 'No Data Found' : ''}
                                        </td>
                                    </tr>
                                    <tr style={{ marginTop: '10px', background: jsondata['aadhaar'] && jsondata['aadhaar'].dateOfBirth ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aadhaar', 'dateOfBirth', aadhaarXmlData.dob)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Date Of Birth</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {aadhaarXmlData ? aadhaarXmlData.dob ? <span style={{ fontSize: '14px' }}>{aadhaarXmlData ? aadhaarXmlData.dob : ""}</span> : 'No Data Found' : ""}

                                        </td>
                                    </tr>
                                    <tr style={{ marginTop: '10px', background: jsondata['aadhaar'] && jsondata['aadhaar'].aadhaarNumber ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aadhaar', 'aadhaarNumber', aadhaarXmlData.maskedAadhaarNumber)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Aadhar Number</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {aadhaarXmlData ? aadhaarXmlData.maskedAadhaarNumber ? <span style={{ fontSize: '14px' }}>{aadhaarXmlData ? aadhaarXmlData.maskedAadhaarNumber : ""}</span> : 'No Data Found' : ""}
                                        </td>
                                    </tr>
                                    <tr style={{ marginTop: '10px', background: jsondata['aadhaar'] && jsondata['aadhaar'].status ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aadhaar', 'status', aadhaarXmlData.status)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Status</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {aadhaarXmlData && aadhaarXmlData.status ? <span style={{ fontSize: '14px', color: aadhaarXmlData.status === true ? '#00C851' : '#F2483A' }}>{aadhaarXmlData ? aadhaarXmlData.status === true ? 'Active' : '' : "N/A"}</span> : "No Data Found"}

                                        </td>
                                    </tr>
                                    {addressDataApi && addressDataApi.combinedAddress && addressDataApi.combinedAddress !== 'null' ?
                                        <tr style={{ marginTop: '10px', background: jsondata['aadhaar'] && jsondata['aadhaar'].address ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aadhaar', 'address', addressDataApi.combinedAddress)}>
                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Address</td>
                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                                {addressDataApi && addressDataApi.combinedAddress ? <span style={{ fontSize: '14px' }}>{addressDataApi !== '' ? addressDataApi.combinedAddress !== null && addressDataApi.combinedAddress !== '' && addressDataApi.combinedAddress !== undefined ? addressDataApi.combinedAddress : '' : "N/A"}</span> : "No Data Found"}

                                            </td>
                                        </tr>
                                        : ""}
                                    <tr style={{ marginTop: '10px', background: jsondata['aadhaar'] && jsondata['aadhaar'].notes ? 'lightgreen' : 'white', cursor: 'pointer', borderBottom: 'none' }} onClick={() => selectData('aadhaar', 'notes', aadhaarXmlData.notes)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'none' }}>Notes</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {aadhaarNotes && aadhaarNotes.notes === 'Verified By Karza' ?
                                                <>
                                                    <i className="fa fa-check-circle" style={{ color: '#4CC78C' }}></i>&nbsp;&nbsp;{aadhaarNotes ? aadhaarNotes.notes : ''}
                                                </> : ""}

                                            {aadhaarNotes && aadhaarNotes.notes === 'Verified By Underwriter' ?
                                                <>
                                                    <i className="fa fa-times-circle" style={{ color: 'red' }}></i>&nbsp;&nbsp;{aadhaarNotes ? aadhaarNotes.notes : ''}
                                                </>
                                                : ""}
                                            {aadhaarNotes && aadhaarNotes.notes !== 'Verified By Underwriter' && aadhaarNotes.notes !== 'Verified By Karza' ?
                                                <>
                                                    {aadhaarNotes.notes}
                                                </>
                                                : ""}

                                        </td>
                                    </tr>

                                </table>
                                <div style={{ background: '#A8C8FF', padding: '10px', borderBottomLeftRadius: '11px', borderBottomRightRadius: '11px', borderTop: '1px solid #A8C8FF' }}>
                                    <div className='row'>
                                        <div className='col-sm-6 col-xs-6' style={{ marginTop: '13px' }}>Download File</div>
                                        <div className='col-sm-6 col-xs-6 text-right'>
                                            {/* <button className='download-btn' onClick={() => MatchFace()}>Match Face </button>&nbsp;&nbsp; */}
                                            <button className='download-btn' onClick={() => downloadFile("download.zip", aadhaarXmlData.file)}><i className='fa fa-download'></i>&nbsp;&nbsp;Download</button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </>
                        : ""}

                    {(kycSubStateAAdhaar || matchedState) ?
                        <>
                            <h5><b>Face Matched Result
                            </b></h5>
                            {allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110'
                                &&matchedState ? <div className='text-left'> <button className='btn btn-primary' onClick={() => MatchFace()}>Match Face </button></div> : ""}

                            {matchedData && Object.keys(matchedData).length > 0 ?
                                <div className='kyc-card-credit' style={{ marginBottom: '20px', borderRadius: '0px' }}>

                                    <table className="opertion-table-credit" >

                                        {matchedData.faceProperties && Object.keys(matchedData.faceProperties).length > 0 &&
                                            Object.keys(matchedData.faceProperties).map((imageKey, index) => (

                                                <tr key={index} style={{ marginTop: '10px', background: '#fff', borderTop: '1px solid #A8C8FF' }}>
                                                    <td style={{ borderTop: 'none', borderLeft: 'none' }}>Number Of Faces {index === 0 ? 'detected in Aadhaar Image' : 'detected in Selfie'}</td>
                                                    <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                                        <b>{matchedData.faceProperties[imageKey].numberOfFaces ? matchedData.faceProperties[imageKey].numberOfFaces : ''}</b>
                                                    </td>
                                                </tr>
                                            ))}
                                        <tr style={{ marginTop: '10px', background: '#fff' }}>
                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Match Score</td>
                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                                <b>{matchedData.matchScore ? matchedData.matchScore : ''}&nbsp;%</b>
                                            </td>
                                        </tr>
                                        <tr style={{ marginTop: '10px', background: '#fff' }}>
                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Confidence</td>
                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                                <b>{matchedData.confidence ? matchedData.confidence : ''}&nbsp;%</b>
                                            </td>
                                        </tr>
                                        <tr style={{ marginTop: '10px', background: '#fff' }}>
                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Match</td>
                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right', textTransform: 'capitalize', color: matchedData.match === 'yes' ? 'green' : 'black' }}>
                                                <b>{matchedData.match ? matchedData.match : ''}</b>
                                            </td>
                                        </tr>
                                        <tr style={{ marginTop: '10px', background: '#fff' }}>
                                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Review Needed</td>
                                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right', textTransform: 'capitalize' }}>
                                                <b>{matchedData.reviewNeeded ? matchedData.reviewNeeded : ''}</b>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                : <div className='text-center'><img src={Nodata} style={{ width: '30%', marginTop: '50px' }} /></div>}
                        </>

                        : ""}






                    {panState ?
                        <>
                            <div className='row'>
                                <div className='col-sm-6 col-xs-6'>
                                    <h5 style={{ marginTop: '40px' }}><b>PAN Card Information</b></h5>
                                </div>
                                <div className='col-sm-6 col-xs-6'>
                                    {admin.emailId !== 'SuperUser' ?
                                        <>
                                            <div style={{ float: 'inline-end' }} >
                                                <span onClick={() => openCommentPopup('pan')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>
                                                {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? <>
                                                    <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'pan')} >
                                                        <option value={''}>Select Option</option>
                                                        <option value={'Verified By Karza'}>Verified By Karza</option>
                                                        <option value={'Verified By Underwriter'}>Verified By Underwriter</option>
                                                        <option value={'Unverified by Karza'}>Unverified by Karza</option>
                                                        <option value={'Unverified by Underwriter'}>Unverified by Underwriter</option>


                                                    </select>

                                                    <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter('panDecision')}>Save</button>
                                                </> : ""}
                                            </div>
                                        </>
                                        : ""}

                                    {admin.emailId === 'SuperUser' ?
                                        <>
                                            <div style={{ float: 'inline-end' }} >
                                                <span onClick={() => openCommentPopup('pan')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>

                                                <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'pan')} >
                                                    <option value={''}>Select Option</option>
                                                    <option value={'Verified By Karza'}>Verified By Karza</option>
                                                    <option value={'Verified By Underwriter'}>Verified By Underwriter</option>
                                                    <option value={'Unverified by Karza'}>Unverified by Karza</option>
                                                    <option value={'Unverified by Underwriter'}>Unverified by Underwriter</option>


                                                </select>

                                                <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter('panDecision')}>Save</button>
                                            </div>
                                        </>
                                        : ""}

                                </div>
                            </div>
                            <div className='kyc-card-credit' style={{ marginBottom: '20px' }}>

                                <table className="opertion-table-credit" >
                                    <tr style={{ background: jsondata['pan'] && jsondata['pan'].status ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('pan', 'status', karzaData.status)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Status</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            <span style={{ fontSize: '14px', color: karzaData.status === 'Active' ? '#00C851' : '#F2483A' }}>{karzaData ? karzaData.status : "N/A"}</span>
                                        </td>
                                    </tr>
                                    <tr style={{ background: jsondata['pan'] && jsondata['pan'].nameOnPan ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('pan', 'nameOnPan', karzaData.namePanAuthentication)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Name on PAN Card </td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            <span style={{ fontSize: '14px' }}>{karzaData ? karzaData.namePanAuthentication : ""}</span>
                                        </td>
                                    </tr>
                                    <tr style={{ background: jsondata['pan'] && jsondata['pan'].panNumber ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('pan', 'panNumber', allDetail.panNumber)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>PAN Number</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {allDetail ? allDetail.panNumber ? <span style={{ fontSize: '14px' }}>{allDetail ? allDetail.panNumber : ""}</span> : 'No Data Found' : ""}

                                        </td>
                                    </tr>
                                    <tr style={{ background: jsondata['pan'] && jsondata['pan'].aadharLinked ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('pan', 'aadharLinked', karzaData.aadhaarLinked)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Aadhar Match</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            <span style={{ fontSize: '14px', color: karzaData.aadhaarLinked === true ? '#00C851' : '#F2483A' }}>{karzaData ? karzaData.aadhaarLinked === true ? 'True' : 'False' : "N/A"}</span>
                                        </td>
                                    </tr>
                                    <tr style={{ background: jsondata['pan'] && jsondata['pan'].dobMatch ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('pan', 'dobMatch', karzaData.dobMatch)} >
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>DOB Match</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            <span style={{ fontSize: '14px', color: karzaData.dobMatch === true ? '#00C851' : '#F2483A' }}>{karzaData ? karzaData.dobMatch === true ? 'True' : 'False' : "N/A"}</span>

                                        </td>
                                    </tr>
                                    {/* <tr style={{background: jsondata['pan'] && jsondata['pan'].nameMatch  ? 'lightgreen' : 'white',cursor:'pointer'}} onClick={() => selectData('pan', 'nameMatch', '')} >
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Name Match</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {aadhaarXmlData && aadhaarXmlData.status ? <span style={{ fontSize: '14px', color: aadhaarXmlData.status === true ? '#00C851' : '#F2483A' }}>{aadhaarXmlData ? aadhaarXmlData.status === true ? 'Active' : '' : "N/A"}</span> : "No Data Found"}
                                            -----------
                                        </td>
                                    </tr> */}
                                    <tr style={{ background: jsondata['pan'] && jsondata['pan'].duplicate ? 'lightgreen' : 'white', cursor: 'pointer', borderBottom: 'none' }} onClick={() => selectData('pan', 'duplicate', karzaData.duplicate)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'none' }}>Duplicate</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', marginTop: '5px', textAlign: 'end', borderBottom: 'none' }}>
                                            {karzaData && karzaData.duplicate === true ? <strong> <span className='text-center' style={{ color: ' #4CC78C', background: '#FFB828', padding: '1px', width: '84px', borderRadius: '4px' }}>Found</span> </strong> : <strong> <span className='text-center' style={{ color: ' #4CC78C', background: '#E1F6EC', padding: '1px', width: '84px', borderRadius: '4px' }}>Not Found</span></strong>}


                                        </td>
                                    </tr>

                                </table>
                                <div onClick={() => selectData('pan', 'notes', panNotes.notes)} style={{ background: jsondata['pan'] && jsondata['pan'].notes ? 'lightgreen' : '#F1F7FF', padding: '10px', borderBottomLeftRadius: '11px', borderBottomRightRadius: '11px', cursor: 'pointer', borderTop: '1px solid #A8C8FF' }}>
                                    <div className='row'>
                                        <div className='col-sm-6 col-xs-6' style={{}}>Notes</div>
                                        {panNotes && panNotes.notes ?

                                            <div className='col-sm-6 col-xs-6 text-right'>
                                                {panNotes && panNotes.notes === 'Verified By Karza' ?
                                                    <>
                                                        <i className="fa fa-check-circle" style={{ color: '#4CC78C' }}></i>&nbsp;&nbsp;{panNotes ? panNotes.notes : ''}
                                                    </> : ""}

                                                {panNotes && panNotes.notes === 'Verified By Underwriter' ?
                                                    <>
                                                        <i className="fa fa-times-circle" style={{ color: 'red' }}></i>&nbsp;&nbsp;{panNotes ? panNotes.notes : ''}
                                                    </>
                                                    : ""}
                                                {panNotes && panNotes.notes !== 'Verified By Underwriter' && panNotes.notes !== 'Verified By Karza' ?
                                                    <>
                                                        {panNotes.notes}
                                                    </>
                                                    : ""}

                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </>
                        : ""}
                    {locationState ?
                        <>
                            <div className='row'>
                                <div className='col-sm-6 col-xs-6'>
                                    <h5 style={{ marginTop: '40px' }}><b>Location Information</b></h5>
                                </div>
                                <div className='col-sm-6 col-xs-6'>
                                    {admin.emailId !== 'SuperUser' ?
                                        <>
                                            <div style={{ float: 'inline-end' }} >
                                                <span onClick={() => openCommentPopup('location')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>
                                                {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? <>
                                                    <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'location')} >
                                                        <option value={''}>Select Option</option>
                                                        <option value={'Verified By Karza'}>Verified By Karza</option>
                                                        <option value={'Verified By Underwriter'}>Verified By Underwriter</option>
                                                        <option value={'Unverified by Karza'}>Unverified by Karza</option>
                                                        <option value={'Unverified by Underwriter'}>Unverified by Underwriter</option>



                                                    </select>

                                                    <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter('locationDecision')}>Save</button>
                                                </> : ""}
                                            </div>
                                        </>
                                        : ""}

                                    {admin.emailId === 'SuperUser' ?
                                        <>
                                            <div style={{ float: 'inline-end' }} >
                                                <span onClick={() => openCommentPopup('location')} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>

                                                <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'location')} >
                                                    <option value={''}>Select Option</option>
                                                    <option value={'Verified By Karza'}>Verified By Karza</option>
                                                    <option value={'Verified By Underwriter'}>Verified By Underwriter</option>
                                                    <option value={'Unverified by Karza'}>Unverified by Karza</option>
                                                    <option value={'Unverified by Underwriter'}>Unverified by Underwriter</option>



                                                </select>

                                                <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter('locationDecision')}>Save</button>
                                            </div>
                                        </>
                                        : ""}

                                </div>
                            </div>
                            <div className='kyc-card-credit' style={{ marginBottom: '20px' }}>
                                <div className='' style={{ display: 'flex' }} >
                                    <div style={{ background: jsondata['location'] && jsondata['location'].crifAddressMatchPercentage ? 'lightgreen' : 'white', borderBottom: '1px solid #A8C8FF', borderRight: '1px solid #A8C8FF', minHeight: '125px', borderTopLeftRadius: '10px', width: '50%', marginLeft: '4px' }}>
                                        <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => selectData('location', 'crifAddressMatchPercentage', chartDataStateLocation[0].value)}>
                                            <div className=''>
                                                <div className='col-sm-8'>
                                                    <div style={{ marginLeft: '10px', marginTop: '20px' }}> <b>CRIF Address Match Percentage<br />
                                                        (%)</b>
                                                    </div>
                                                </div>
                                                <div className='col-sm-4 remove-rechart-line'>
                                                    {chartDataStateLocation.length > 0 ?
                                                        <PieChart width={100} height={100}>
                                                            <Pie
                                                                data={chartDataStateLocation}
                                                                cx={50}
                                                                cy={45}
                                                                innerRadius={30}
                                                                outerRadius={40}
                                                                startAngle={450}
                                                                endAngle={90}
                                                                // fill="#1D3557"
                                                                paddingAngle={1}
                                                                dataKey="value"
                                                                label={renderCustomizedLabelLocation}
                                                            >
                                                                {chartDataStateLocation.map((entry, index) => (
                                                                    <Cell radius={[5, 5, 5, 5]} key={`cell-${index}`} fill={COLORSPINK[index % COLORSPINK.length]} />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>

                                                        : "No Data Found"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #A8C8FF', minHeight: '125px', width: '50%', marginRight: '4px' }}>

                                        <div className='' style={{ marginTop: '10px' }}>
                                            <div className='col-sm-6 col-xs-6'>
                                                <div style={{ marginTop: '30px', marginLeft: '10px' }}><b>Location</b></div>
                                            </div>
                                            <div className='col-sm-6 col-xs-6 text-right' style={{ marginTop: '40px', textDecorationLine: 'underline' }}>
                                                <a style={{ color: '#2B78FF', cursor: 'pointer' }} onClick={() => locationHandler()}><i className='fa fa-map-marker'></i>&nbsp;&nbsp;View Location</a>
                                                {/* {aadhaarXmlData && aadhaarXmlData.image ? <img width='80px' style={{ borderRadius: '5px' }} src={'data:image/png;base64,' + aadhaarXmlData.image} /> : ""} */}
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <table className="opertion-table-credit" >
                                    <tr onClick={() => selectData('location', 'addressStateMatch', aadhaarXmlData && aadhaarXmlData.aadharStateMatch === true ? true : false)} style={{ background: jsondata['location'] && jsondata['location'].addressStateMatch ? 'lightgreen' : 'white' }}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Aadhaar State Match</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {aadhaarXmlData && aadhaarXmlData.aadharStateMatch === true ? <span style={{ color: '#E1F6EC', fontWeight: '700' }}>True</span> : <span style={{ color: '#FF5C5D', fontWeight: '700' }}>False</span>}
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>  Aadhaar Address Match</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            -------
                                        </td>
                                    </tr> */}
                                    <tr onClick={() => selectData('location', 'crifPincodeMatch', aadhaarXmlData && aadhaarXmlData.crifPincodeMatch === true ? true : false)} style={{ background: jsondata['location'] && jsondata['location'].crifPincodeMatch ? 'lightgreen' : 'white' }}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'none' }}>CRIF Pincode Match</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderBottom: 'none', textAlign: 'right' }}>
                                            {personalData && personalData.crifPincodeMatch === true ? <span style={{ color: '#4CC78C', fontWeight: '700' }}>True</span> : <span style={{ color: '#FF5C5D', fontWeight: '700' }}>False</span>}

                                        </td>
                                    </tr>


                                </table>
                                <div onClick={() => selectData('location', 'notes', locationNotes && locationNotes.notes ? locationNotes.notes : null)} style={{ background: jsondata['location'] && jsondata['location'].notes ? 'lightgreen' : '#F1F7FF', padding: '10px', borderBottomLeftRadius: '11px', borderBottomRightRadius: '11px', borderTop: '1px solid #A8C8FF' }}>
                                    <div className='row'>
                                        <div className='col-sm-6 col-xs-6' style={{ marginTop: '' }}>Notes</div>
                                        {locationNotes && locationNotes.notes ?

                                            <div className='col-sm-6 col-xs-6 text-right'>
                                                {locationNotes && locationNotes.notes === 'Verified By Karza' ?
                                                    <>
                                                        <i className="fa fa-check-circle" style={{ color: '#4CC78C' }}></i>&nbsp;&nbsp;{locationNotes ? locationNotes.notes : ''}
                                                    </> : ""}

                                                {locationNotes && locationNotes.notes === 'Verified By Underwriter' ?
                                                    <>
                                                        <i className="fa fa-times-circle" style={{ color: 'red' }}></i>&nbsp;&nbsp;{locationNotes ? locationNotes.notes : ''}
                                                    </>
                                                    : ""}
                                                {locationNotes && locationNotes.notes !== 'Verified By Underwriter' && locationNotes.notes !== 'Verified By Karza' ?
                                                    <>
                                                        {locationNotes.notes}
                                                    </>
                                                    : ""}

                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </>
                        : ""}
                    {amlState ?
                        <>
                            {allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110'
                                && amlData ? (amlData.amlCheckDone !== true || amlData.action === '') ? <button style={{ marginTop: '20px' }} className='btn btn-primary' onClick={() => checkForPep()}>Check For AML/PEP</button> : "" : ""}
                            <h5 style={{ marginTop: '40px' }}><b>AML/ PEP Exposed</b></h5>
                            <div className='kyc-card-credit' style={{ marginBottom: '20px' }}>

                                <table className="opertion-table-credit" >
                                    <tr style={{ background: jsondata['aml'] && jsondata['aml'].amlCheckDone ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aml', 'amlCheckDone', amlData.amlCheckDone)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Aml Check Done </td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            <span style={{ fontSize: '14px' }}>{amlData ? amlData.amlCheckDone === true ? 'True' : 'false' : ""}</span>
                                        </td>
                                    </tr>
                                    <tr style={{ background: jsondata['aml'] && jsondata['aml'].checkedOn ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aml', 'checkedOn', allDetail.checkedOn)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Checked On</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            {amlData ? amlData.checkedOn ? <span style={{ fontSize: '14px' }}>{amlData.checkedOn ? moment(amlData.checkedOn).format('DD-MM-YYYY') : ""}</span> : 'No Data Found' : ""}

                                        </td>
                                    </tr>
                                    <tr style={{ background: jsondata['aml'] && jsondata['aml'].action ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aml', 'action', amlData.action)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Action suggested by Screenza (Trackwizz)</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            <span style={{ fontSize: '14px' }}>{amlData ? amlData.action : "N/A"}</span>
                                        </td>
                                    </tr>


                                    <tr style={{ background: jsondata['aml'] && jsondata['aml'].hitsDetected ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aml', 'hitsDetected', amlData.hitsDetected)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Hits Detected</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right' }}>
                                            <span style={{ fontSize: '14px' }}>{amlData ? amlData.hitsDetected : ''}</span>
                                        </td>
                                    </tr>
                                    <tr style={{ background: jsondata['aml'] && jsondata['aml'].overallStatus ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aml', 'overallStatus', amlData.overallStatus)} >
                                        <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'none' }}>Over All Status</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', textAlign: 'right', borderBottom: 'none' }}>
                                            <span style={{ fontSize: '14px' }}>{amlData ? amlData.overallStatus === 'AcceptedByTW' ? 'Accepted By Trackwizz' : '' : ""}</span>

                                        </td>
                                    </tr>

                                    {/* <tr style={{ background: jsondata['aml'] && jsondata['aml'].pan ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aml', 'pan', amlData.pan)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Pan</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', marginTop: '5px', textAlign: 'end' }}>
                                            {amlData && amlData.pan}


                                        </td>
                                    </tr> */}

                                    {/* <tr style={{ background: jsondata['aml'] && jsondata['aml'].requestId ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData('aml', 'requestId', amlData.requestId)}>
                                        <td style={{ borderTop: 'none', borderLeft: 'none' }}>Request Id</td>
                                        <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', marginTop: '5px', textAlign: 'end' }}>
                                            {amlData && amlData.requestId}


                                        </td>
                                    </tr> */}



                                </table>
                                {amlData !== '' && amlData.report !== '' && amlData.report !== null && amlData.report !== undefined ?
                                    <div style={{ background: '#A8C8FF', padding: '10px', borderBottomLeftRadius: '11px', borderBottomRightRadius: '11px', borderTop: '1px solid #A8C8FF' }}>
                                        <div className='row'>
                                            <div className='col-sm-6 col-xs-6' style={{ marginTop: '13px' }}>View Report</div>
                                            <div className='col-sm-6 col-xs-6 text-right'>

                                                <button className='download-btn' onClick={() => viewAmlReport()}><i className='fa fa-info'></i>&nbsp;&nbsp;View</button>
                                            </div>

                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </>
                        : ""}

                </div>
            </div>
        </div>
    </>
)
export default Kyc