import React, { useEffect, useState } from "react";
import { saveEmpDataApi } from "../AdminActionCreator.component";
import Popup from '../../../presentationals/Popup/Popup.component';
import { saveEmployeeDataForTarget } from "./targetactioncreator";

const EmployeesTarget = ({ editData }) => {
    const [employeeData, setEmployeeData] = useState({
        employeeName: "",
        employeeType: "",
        employeeDepartment: "",
        joiningDate: "",
        salary: 0,
        vairable: 0,
        otherComponent: 0,
        isActive: false,
        inHandAmount: 0,
        taxDeducted: 0,
    });

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!employeeData.employeeName) {
            formErrors.employeeName = "Employee Name is required";
            isValid = false;
        }
        if (!employeeData.employeeType) {
            formErrors.employeeType = "Employee Type is required";
            isValid = false;
        }
        if (!employeeData.employeeDepartment) {
            formErrors.employeeDepartment = "Department is required";
            isValid = false;
        }
        if (!employeeData.joiningDate) {
            formErrors.joiningDate = "Joining Date is required";
            isValid = false;
        }
        if (employeeData.salary <= 0) {
            formErrors.salary = "Salary must be greater than 0";
            isValid = false;
        }
        if (employeeData.inHandAmount <= 0) {
            formErrors.inHandAmount = "In-Hand Amount must be greater than 0";
            isValid = false;
        }
        if (employeeData.taxDeducted < 0) {
            formErrors.taxDeducted = "Tax Deducted cannot be negative";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Employee Data Saved", employeeData);
            saveEmployeeDataForTarget(employeeData, callback => {
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };
    useEffect(() => {
        if (editData.id) {
            setEmployeeData({
                employeeName: editData.employeeName || "",
                employeeType: editData.employeeType || "",
                employeeDepartment: editData.employeeDepartment || "",
                joiningDate: editData.joiningDate || "",
                salary: editData.salary || 0,
                vairable: editData.vairable || 0,
                otherComponent: editData.otherComponent || 0,
                isActive: editData.isActive || false,
                inHandAmount: editData.inHandAmount || 0,
                taxDeducted: editData.taxDeducted || 0,
                employeeID: editData.employeeID || '',
                id: editData.id || '',


            });
        }
    }, [editData]);


    return (
        <div className="container">
            {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
            <div className="target-box">
                <h3>Add/Update Employee Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Employee Name</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="employeeName"
                                        value={employeeData.employeeName}
                                        onChange={handleChange}
                                    />
                                    {errors.employeeName && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.employeeName}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Employee Type</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="employeeType"
                                        value={employeeData.employeeType}
                                        onChange={handleChange}
                                    />
                                    {errors.employeeType && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.employeeType}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Department</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="employeeDepartment"
                                        value={employeeData.employeeDepartment}
                                        onChange={handleChange}
                                    />
                                    {errors.employeeDepartment && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.employeeDepartment}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Joining Date</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="date"
                                        name="joiningDate"
                                        value={employeeData.joiningDate}
                                        onChange={handleChange}
                                    />
                                    {errors.joiningDate && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.joiningDate}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Salary</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="salary"
                                        value={employeeData.salary}
                                        onChange={handleChange}
                                    />
                                    {errors.salary && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.salary}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Variable</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="vairable"
                                        value={employeeData.vairable}
                                        onChange={handleChange}
                                    />
                                    {errors.vairable && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.vairable}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Other Component</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="otherComponent"
                                        value={employeeData.otherComponent}
                                        onChange={handleChange}
                                    />
                                    {errors.otherComponent && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.otherComponent}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Is Active</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="isActive"
                                        checked={employeeData.isActive}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '15px' }}>In-Hand Amount</label>
                                </div>
                                <div style={{ width: '50%' }}>

                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="inHandAmount"
                                        value={employeeData.inHandAmount}
                                        onChange={handleChange}
                                    />
                                    {errors.inHandAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.inHandAmount}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '15px' }}>Tax Deducted</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="taxDeducted"
                                        value={employeeData.taxDeducted}
                                        onChange={handleChange}
                                    />
                                    {errors.taxDeducted && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.taxDeducted}</span>
                                    )}
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Employee Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmployeesTarget;
