import React, { useEffect, useState } from "react";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import { getAllExpenseCategory, saveExpenseSubCategory } from "../../targetactioncreator";

const EditCategory = ({ editData }) => {
    const [categoryData, setCategoryData] = useState({
        categoryId: "",
        subCategoryType: "",
        subCategoryDescription: ""
    });
    // const categoryOptions = [{
    //     "categoryId": "SWXaW31",
    //     "subCategoryType": "Sample1",
    //     "subCategoryDescription": "Sample1",
    //     "id": "2"

    // }]
    const [categoryOptions, setcategoryOptions] = useState('')

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategoryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!categoryData.subCategoryType) {
            formErrors.subCategoryType = "Subcategory Type is required";
            isValid = false;
        }
        if (!categoryData.subCategoryDescription) {
            formErrors.subCategoryDescription = "Subcategory Description is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Category Data Saved", categoryData);
            saveExpenseSubCategory(categoryData, callback => {
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setCategoryData({
                categoryId: editData.categoryId || "",
                subCategoryType: editData.subCategoryType || "",
                subCategoryDescription: editData.subCategoryDescription || "",
            });
        }

    }, [editData]);
    useEffect(() => {
        getAllExpenseCategory(Callback => {
            setcategoryOptions(Callback.data)
        })
    }, [])

    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            <div className="target-box">
                <h3>Add/Update Subcategory Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Category</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <select
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        name="categoryId"
                                        value={categoryData.categoryId}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Category</option>
                                        {categoryOptions?categoryOptions.map((category,i) => (
                                            <option key={category.id} value={category.categoryId}>
                                                {category.categoryDescription}
                                            </option>
                                        )):""}
                                    </select>
                                    {errors.categoryId && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.categoryId}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Subcategory Type</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="subCategoryType"
                                        value={categoryData.subCategoryType}
                                        onChange={handleChange}
                                    />
                                    {errors.subCategoryType && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.subCategoryType}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-sm-12 col-xs-12">
                            <div style={{ width: '' }}>
                                <label style={{ marginTop: '10px' }}>Subcategory Description</label>
                            </div>
                            <div style={{ width: '' }}>
                                <textarea
                                    style={{ background: '#fff', border: '1px solid' }}
                                    className="form-control"
                                    type="text"
                                    name="subCategoryDescription"
                                    value={categoryData.subCategoryDescription}
                                    onChange={handleChange}
                                />
                                {errors.subCategoryDescription && (
                                    <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.subCategoryDescription}</span>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary">Save Subcategory Data</button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default EditCategory;
