import React from 'react';
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import { STRING, ROLE } from '../../../../utils/Constant'
import DotLoader from '../../Loader/layerLoader.component'
import { filterCaseInsensitive } from '../../../../utils/commonUtility'
import Workbook from 'react-excel-workbook'
import { Pagination } from '@mui/material';


const PhocketCustomerDetails = ({userDetailHandler,forwordPageState,handleChangeNewPagination,pageNumber, paginationHandler,phocketTablePagination, tableMinPagination, tableMaxPagination, dataFilter, recoveryComunicateHandler, ignoreHandler,
    forwordpage, backpage, allUserDetail, loadarState, refreshpage, chequeNoticeShowHandler,
    getFilterValue, FilteredData, getPageValue, pageIndexToShow, nashHandler, nachCheckbox,
    searchByAppNo, searchByemail, searchBymobile, disburseLoanId, saveDisburseLoanId,
    addLoanId, searchByDate, searchByDateShow, admin }) => (
    <div className="container-fluid">
        {/* <div className="row">
            <h3 className="text-center blue-text">Disbursed Applications</h3>
        </div> */}
        {/* <div className="row form-group">
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Application No.</label>
                <input type="number" className="form-control" value={searchByAppNo} onChange={e => dataFilter(e, "applicationNo")} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Email ID</label>
                <input type="email" className="form-control" value={searchByemail} onChange={e => dataFilter(e, "email")} />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px" ><span className="fa fa-search" /> Mobile No.</label>
                <input type="number" value={searchBymobile} onChange={e => dataFilter(e, "mobileNo")} className="form-control" />
            </div>
            <div className="col-sm-2 col-xs-12">
                <label className="font-12px"><span className="fa fa-search" /> Date</label>
                <DatePicker
                    value={searchByDate}
                    selected={searchByDateShow}
                    onChange={e => dataFilter(e, "date")}
                    className="form-control"
                    dateFormat="DD/MM/YYYY"
                />
            </div>
        </div> */}

        {/* <div className="row">
            <div className="col-xs-12 text-right">
                <Workbook filename="ApplicationStatus.xlsx" element={<button className="profile-save-btn margin-0"><span className="fa fa-download"></span>&nbsp;&nbsp;Excel</button>}>
                    <Workbook.Sheet data={allUserDetail} name="Sheet A">
                        <Workbook.Column label="Loan Id" value="applicationLoanId" />
                        <Workbook.Column label="Application Id" value="applicationId" />
                        <Workbook.Column label="UserName" value="userName" />
                        <Workbook.Column label="Email" value="emailId" />
                        <Workbook.Column label="Contact" value="mobileNumber" />
                        <Workbook.Column label="Installment" value="loanEmi" />
                        <Workbook.Column label="Payback Date" value="loanPaybackDate" />
                        <Workbook.Column label="Payback Amount" value="loanPaybackAmount" />
                        <Workbook.Column label="Pay ON Today" value="payOnToday" />
                        <Workbook.Column label="Overdue Days" value="count" />
                    </Workbook.Sheet>
                </Workbook>
                &nbsp;
                <button className="admin-pagination-btn" id="forword" onClick={e => refreshpage()}><span className="fa fa-refresh fa-spin" /></button>
                <button className="admin-pagination-btn" id="backword" onClick={e => backpage()}><span className="fa fa-chevron-left" /></button>
                <button className="admin-pagination-btn" id="forword" onClick={e => forwordpage()}><span className="fa fa-chevron-right" /></button>
            </div>
        </div> */}

        <div className="row text-center">
            <div className="col-xs-12 overflow-x-auto no-padding">
                {/* {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                        <ReactTable
                            getTrProps={(state, rowInfo, column) => {
                                return {
                                    style: {
                                        visibility: rowInfo != undefined ? rowInfo.row.followUpStatusCode == STRING.DISBURSED || rowInfo.row.followUpStatusCode == STRING.DISBURSED ? 'visible' : 'hidden' : ""
                                    }
                                };
                            }}
                            data={allUserDetail}
                            columns={[{
                                Header: 'Nach',
                                accessor: 'loanId',
                                filterable: false,
                                show: nachCheckbox,
                                className: 'width-30px',
                                headerClassName: 'width-30px content-none',
                                Cell: row => (
                                    <div>
                                        {admin != null ? admin.rolelist.indexOf(ROLE.NACH_CHANGE) >= 0 ?
                                            <div>
                                                <input className="phocket-checkbox" type="checkbox" id={"nash_" + row.original.loanId} onChange={(e) => nashHandler(e, row.original)} checked={row.original.nachStatus} />
                                                <label htmlFor={"nash_" + row.original.loanId}><span></span></label>
                                            </div>
                                            : row.original.nachStatus : null}
                                    </div>
                                )
                            }, {
                                Header: 'LoanId',
                                accessor: 'applicationLoanId',
                            }, {
                                Header: 'Application Id',
                                accessor: 'applicationId'
                            }, {
                                Header: 'Name',
                                accessor: 'userName'
                            }, {
                                Header: 'Mobile No.',
                                accessor: 'mobileNumber'
                            }, {
                                Header: 'Email ID',
                                accessor: 'emailId'
                            }, {
                                Header: 'Installment',
                                accessor: 'loanEmi'
                            }, {
                                Header: 'PayBack Date',
                                accessor: 'loanPaybackDate'
                            }, {
                                Header: 'PayBack Amount',
                                accessor: 'loanPaybackAmount'
                            }, {
                                Header: 'Pay on Today',
                                accessor: 'payOnToday'
                            }, {
                                Header: 'Count',
                                accessor: 'count'
                            }, {
                                Header: 'Communicate',
                                accessor: 'loanId',
                                Cell: row => (
                                    <div>
                                        <button onClick={e => recoveryComunicateHandler(row.original)}><span className="fa fa-envelope-o" /></button>
                                    </div>
                                )
                            }, {
                                Header: 'status',
                                accessor: 'followUpStatusCode',
                                show: false,
                            }]}
                            defaultPageSize={10}
                            className="-striped -highlight phocketAdmin_table"
                            filterable={true}
                            defaultFilterMethod={filterCaseInsensitive}
                            onFilteredChange={(filtered) => getFilterValue(filtered)}
                            defaultFiltered={FilteredData}
                            onPageChange={(pageIndex) => getPageValue(pageIndex)}
                            page={pageIndexToShow}
                            previousText={<span className="fa fa-chevron-left" />}
                            nextText={<span className="fa fa-chevron-right" />}
                        /> : ""} */}
                {loadarState == false && allUserDetail != null && allUserDetail != '' && allUserDetail.length > 0 ?
                    <div className="overflow-x-auto phocket-table-new">
                        <table className="">
                            <thead>
                                <tr>
                                    <th className='text-center'>Details</th>
                                    <th className='text-center'>NACH</th>
                                    <th className='text-center'>Loan Id</th>
                                    <th className='text-center'>Application Id</th>
                                    <th className='text-center'>Name</th>
                                    <th className='text-center'>Mobile No.</th>
                                    <th className='text-center'>Email ID</th>
                                    <th className='text-center'>Installment</th>
                                    <th className='text-center'>PayBack Date</th>
                                    <th className='text-center'>Pay on Today</th>
                                    <th className='text-center'>Count</th>
                                    <th className='text-center'>Communicate </th>
                                    <th className='text-center'>Status</th>


                                </tr>

                            </thead>
                            <tbody>
                                {allUserDetail.slice(tableMinPagination, tableMaxPagination).map((data, i) => {
                                    if (data.followUpStatusCode == STRING.DISBURSED || data.followUpStatusCode == STRING.DISBURSED) {
                                        return (
                                            <tr key={i} >
                                                <td><i className='fa fa-info' style={{cursor:'pointer'}} onClick={()=>userDetailHandler(data)}></i></td>
                                                <td>
                                                    <div>
                                                        {admin != null ? admin.rolelist.indexOf(ROLE.NACH_CHANGE) >= 0 ?
                                                            <div>
                                                                <input className="phocket-checkbox" type="checkbox" id={"nash_" + data.loanId} onChange={(e) => nashHandler(e, data)} checked={data.nachStatus} />
                                                                <label htmlFor={"nash_" + data.loanId}><span></span></label>
                                                            </div>
                                                            : data.nachStatus : null}
                                                    </div>
                                                </td>
                                                <td> {data.applicationLoanId}</td>
                                                <td> {data.applicationId}</td>
                                                <td style={{ whiteSpace: 'break-spaces' }}>  {data.userName}</td>
                                                <td> {data.mobileNumber}</td>
                                                <td> {data.emailId}</td>
                                                <td> {data.loanEmi}</td>
                                                <td> {(data.loanPaybackDate).split(' ')[0]}</td>
                                                <td> {data.payOnToday}</td>
                                                <td> {data.count}</td>
                                                <td><div>
                                                    <button onClick={e => recoveryComunicateHandler(data)}><span className="fa fa-envelope-o" /></button>
                                                </div> </td>
                                                <td>{data.applicationStatus}</td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                        <div className="container-fluid">
                        <div style={{ float: 'right', marginBottom: '15px', marginTop: '10px', display: 'flex' }}>
                            {forwordPageState > 1 ? <div className='grey-circle' onClick={e => paginationHandler('left')}><i className="fa fa-chevron-left" style={{ cursor: 'pointer', marginTop: '15px' }}  ></i></div> : ""}  <Pagination size='large' onChange={handleChangeNewPagination} color="primary"
                                count={Math.ceil(allUserDetail.length / 10)}
                                page={pageNumber} />
                            {/* {Math.ceil(allUserDetail.length / 10) === 10 ?  */}
                            <div className='grey-circle' onClick={e => paginationHandler('right')}><i className="fa fa-chevron-right" style={{ cursor: 'pointer', marginTop: '15px' }}  ></i></div>
                            {/* //  : ""} */}

                        </div>
                            {/* <div className="row">
                                <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default  btn-lg btn-block mage" id="backword" onClick={e => backpage('left')}><i className="fa fa-chevron-left" ></i><i className="fa fa-chevron-left" ></i></button></div>
                                <div className="col-sm-3 col-xs-12 no-padding"><button type="button" className="btn btn-default  btn-lg btn-block red  " onClick={e => phocketTablePagination(allUserDetail, 'back')}><i className='fa fa-chevron-left'></i></button></div>
                                <div className="col-sm-2 col-xs-12  phoc" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + allUserDetail.length}</div>
                                <div className="col-sm-3 col-xs-12 no-padding" ><button type="button" className="btn btn-default  btn-lg btn-block red" onClick={e => phocketTablePagination(allUserDetail, 'forward')}><i className='fa fa-chevron-right'></i></button></div>
                                <div className="col-sm-2 col-xs-12 no-padding"> <button type="button" className="btn btn-default  btn-lg btn-block mage" id="forword" onClick={e => forwordpage('right')}><i className="fa fa-chevron-right" ></i><i className="fa fa-chevron-right" ></i></button></div> 
                        </div> */}
                        </div>
                    </div>
                    : ""}
                {loadarState == true ?
                    <DotLoader />
                    : ""}
            </div>
        </div>
    </div>
)

export default PhocketCustomerDetails;
