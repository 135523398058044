import React, { useEffect, useState } from "react";
import { getAllExpenseSubCategory } from "../../targetactioncreator";

const SubCategoryList = ({ editHandler,ActiveMonthFilter }) => {
    // const expenseData = {
    //     "categoryId": "SWXaW31",
    //     "subCategoryType": "Sample1",
    //     "subCategoryDescription": "Sample1",
    //     "id":"2"

    // };
    const [expenseData, setExepensedata] = useState({})
    useEffect(() => {
        getAllExpenseSubCategory(callback => {
            setExepensedata(callback.data)
        })
    }, [ActiveMonthFilter])

    const handleDelete = () => {
    };
   
    return (
        <div className="container Executor">
            <div
                className=""
                style={{
                    background: '#f2f2f2',
                    padding: '10px',
                    margin: '50px auto 0',
                    borderRadius: '5px',
                    overflow: 'auto',
                    maxWidth: '1000px'
                }}
            >
                <div className="">
                    <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Sub Category Type</th>
                                <th>Category Sub Description</th>

                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                        {expenseData !== '' && expenseData.length > 0 ? expenseData.map((data, i) => {
                            return(

                            <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                <td >{data.subCategoryType}</td>
                                <td>{data.subCategoryDescription}</td>
                                <td>
                                    <i
                                        className="fa fa-pencil-square-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => editHandler(data)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Edit Expense"
                                        title="Edit Expense"
                                    ></i>
                                </td>
                                <td>
                                    <i
                                        className="fa fa-trash-o fa-lg"
                                        aria-hidden="true"
                                        onClick={() => handleDelete(data)}
                                        style={{ cursor: 'pointer' }}
                                        aria-label="Delete Expense"
                                        title="Delete Expense"
                                    ></i>
                                </td>
                            </tr>
                        )}):""}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SubCategoryList;
