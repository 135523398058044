import React, { useEffect, useState } from "react";
import { getAllExpense } from "../targetactioncreator";
import ReactApexChart from 'react-apexcharts';
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import Nodata from '../../../../../images/nodata.png'
const ExpensesList = ({ editHandler, ActiveMonthFilter }) => {
    // const expenseData = {
    //     "expenseAmount": 500.75,
    //     "addedBy": "John Doe",
    //     "gstAmount": 75.11,
    //     "isGSTInclusive": true,
    //     "isVerified": false,
    //     "verifiedBy": "Auditor Name" ,
    //     "id":"1"
    // };
    // const [expenseData, setExepensedata] = useState([
    //     {
    //         "expenseAmount": 500.75,
    //         "addedBy": "John Doe",
    //         "gstAmount": 75.11,
    //         "isGSTInclusive": true,
    //         "isVerified": false,
    //         "verifiedBy": "Auditor Name",
    //         "id": "1",
    //         "graphData": [{
    //             name: 'Income',
    //             type: 'column',
    //             data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
    //         }, {
    //             name: 'Cashflow',
    //             type: 'column',
    //             data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    //         }, {
    //             name: 'Revenue',
    //             type: 'line',
    //             data: [20, 29, 37, 36, 44, 45, 50, 58]
    //         }]
    //     },
    //     {
    //         "expenseAmount": 500.75,
    //         "addedBy": "John Doe",
    //         "gstAmount": 75.11,
    //         "isGSTInclusive": true,
    //         "isVerified": false,
    //         "verifiedBy": "Auditor Name",
    //         "id": "1",
    //         "graphData": [{
    //             name: 'Income',
    //             type: 'column',
    //             data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
    //         }, {
    //             name: 'Cashflow',
    //             type: 'column',
    //             data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    //         }, {
    //             name: 'Revenue',
    //             type: 'line',
    //             data: [20, 29, 37, 36, 44, 45, 50, 58]
    //         }]
    //     },

    // ])
    const [expenseData,setExepensedata]=useState('')
    const [openAccordion, setOpenAccordion] = useState(null);


    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };


    useEffect(() => {
        getAllExpense(callback => {
            setExepensedata(callback.data)
        })
    }, [ActiveMonthFilter])


    const tableDatacheck = (data) => {
        if (data == null || data == undefined || data == '') {
            return '-'
        } else {
            return data
        }
    }

    const handleDelete = () => {
    };


    const options = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        xaxis: {
            categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
        },
        yaxis: [
            {
                seriesName: 'Income',
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    }
                },
                title: {
                    text: "Income (thousand crores)",
                    style: {
                        color: '#008FFB',
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: 'Cashflow',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#00E396'
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    }
                },
                title: {
                    text: "Operating Cashflow (thousand crores)",
                    style: {
                        color: '#00E396',
                    }
                },
            },
            {
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019'
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: "Revenue (thousand crores)",
                    style: {
                        color: '#FEB019',
                    }
                }
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
    }





    return (
        // <div className="container-fluid Executor">
        //     <div
        //         className=""
        //         style={{
        //             background: '#f2f2f2',
        //             padding: '10px',
        //             marginTop: '50px',
        //             borderRadius: '5px',
        //             overflow: 'auto',
        //             // maxWidth: '1000px'
        //         }}
        //     >
        //         <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
        //             <thead>
        //                 <tr>
        //                     <th>Expense Amount</th>
        //                     <th>Added By</th>
        //                     <th>GST Amount</th>
        //                     <th>GST Inclusive</th>
        //                     <th>Verified</th>
        //                     <th>Verified By</th>
        //                     <th>Edit</th>
        //                     <th>Delete</th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                  <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }} >
        //                     <td className='text-center'>{expenseData.expenseAmount}</td>
        //                     <td>{expenseData.addedBy}</td>
        //                     <td>{expenseData.gstAmount}</td>
        //                     <td style={{ textAlign: 'center' }}>
        //                         <input
        //                             type='checkbox'
        //                             checked={expenseData.isGSTInclusive}
        //                             readOnly
        //                             aria-label="GST Inclusive"
        //                         />
        //                     </td>
        //                     <td style={{ textAlign: 'center' }}>
        //                         <input
        //                             type='checkbox'
        //                             checked={expenseData.isVerified}
        //                             readOnly
        //                             aria-label="Verified"
        //                         />
        //                     </td>
        //                     <td>{expenseData.verifiedBy}</td>
        //                     <td>
        //                         <i
        //                             className="fa fa-pencil-square-o fa-lg"
        //                             aria-hidden="true"
        //                             onClick={() => editHandler(expenseData)}
        //                             style={{ cursor: 'pointer' }}
        //                             aria-label="Edit Expense"
        //                             title="Edit Expense"
        //                         ></i>
        //                     </td>
        //                     <td>
        //                         <i
        //                             className="fa fa-trash-o fa-lg"
        //                             aria-hidden="true"
        //                             onClick={() => handleDelete(expenseData)}
        //                             style={{ cursor: 'pointer' }}
        //                             aria-label="Delete Expense"
        //                             title="Delete Expense"
        //                         ></i>
        //                     </td>
        //                 </tr> 
        //             </tbody>
        //         </table>
        //     </div>
        // </div>


        <div className="projection-new">

            <div className="container-fluid Projection" style={{ padding: '20px 50px' }}>


                <div className="accords openaccord" style={{ background: '#fff' }}>
                    <div className="">
                        {expenseData && expenseData?.length > 0 ? expenseData.map((item, index) => (
                            <AccordionItem
                                key={index}
                                id={index}
                                isOpen={openAccordion === index}
                                toggleAccordion={toggleAccordion}
                                item={item}
                                expenseData={expenseData}
                                tableDatacheck={tableDatacheck}
                                editHandler={editHandler}
                                handleDelete={handleDelete}
                                options={options}


                            />
                        )) :
                            <div className="text-center" style={{ marginTop: '20px', marginBottom: '20px' }}><img width={'30%'} src={Nodata} /></div>
                        }

                    </div>

                </div>
            </div>


        </div>
    );
};

export default ExpensesList;


function AccordionItem({ id, isOpen, toggleAccordion, expenseData, item, tableDatacheck, editHandler, handleDelete, options }) {
    return (
        <div className={`accords accordsub ${isOpen ? 'openaccord' : ''}`}>
            {console.log(expenseData, 'expenseDataexpenseData')}
            <div className="heading table-responsive">
                <div className="box" onClick={() => toggleAccordion(id)} style={{ backgroundColor: isOpen ? 'black' : 'white' }}>
                    <h5 style={{ color: isOpen ? 'white' : 'black' }}>
                        {isOpen ? <ArrowUpward /> : <ArrowDownward />}
                    </h5>
                </div>
                <table className="table text-center tableheader">
                    <tbody>
                        {expenseData !== '' && expenseData.length > 0 ? expenseData.map((data, i) => {
                            return (
                                <tr>
                                    <td className='td1 subtd' style={{ width: '100px', textAlign: 'left', border: 'none' }}>Expense Amount</td>
                                    <td><b>{data.expenseAmount}</b></td>
                                    <td>
                                        <i
                                            className="fa fa-pencil-square-o fa-lg"
                                            aria-hidden="true"
                                            onClick={() => editHandler(data)}
                                            style={{ cursor: 'pointer' }}
                                            aria-label="Edit Expense"
                                            title="Edit Expense"
                                        ></i>
                                    </td>
                                    <td>
                                        <i
                                            className="fa fa-trash-o fa-lg"
                                            aria-hidden="true"
                                            onClick={() => handleDelete(data)}
                                            style={{ cursor: 'pointer' }}
                                            aria-label="Delete Expense"
                                            title="Delete Expense"
                                        ></i>
                                    </td>
                                </tr>
                            )
                        }) : ""}

                    </tbody>
                </table>
            </div>

            {isOpen && (
                <div className="bottomtable">
                    <div className={`subbottom table-responsive ${isOpen ? 'scrollanimation' : ''}`} style={{ overflow: 'hidden', border: 'none' }}>
                        <div className="row">
                            <div className="col-md-6 col-xs-12" style={{ padding: '10px 8px' }}>
                                <div className="tableedit" style={{ boxShadow: '3px 3px 9px 1px #c4c1c1', borderRadius: '6px', height: '350px', padding: '10px' }}>
                                    <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                        <table style={{ marginBottom: '10px' }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Added By</th>
                                                    <td>{tableDatacheck(item.addedBy)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>GST Amount</th>
                                                    <td>{tableDatacheck(item.gstAmount)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>GST Inclusive</th>
                                                    <td>{item.isGSTInclusive ? 'Yes' : 'No'}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Verified</th>
                                                    <td>{item.isVerified ? 'Yes' : 'No'}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Verified By</th>
                                                    <td>{tableDatacheck(item.verifiedBy)}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12" style={{ padding: '10px 8px' }}>
                                <div className="tableedit" style={{ boxShadow: '3px 3px 9px 1px #c4c1c1', borderRadius: '6px', height: '350px' }}>
                                    <div id="chart">
                                        <ReactApexChart options={options} series={item.graphData} type="line" height={350} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>);
}
