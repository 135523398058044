import React from 'react'
import { useState } from 'react'

import { getNotes, getNotesnew, getPersonalAnalysisReport, kycAnalysisSideApi, matchFaceApi, saveCommentForCreditAssesment, saveWorkingHistoryApi, getFaceData, getAmlData, getAMlPEPDataApi } from '../Analysis/analysisactionCreator'
import { useEffect } from 'react'
import Kyc from './kyc'
import { aadharVerificationDetails, aadharXmlDetails, panProfileDetails, saveUnderWriterApi } from '../AdminActionCreator.component'
import InternalDedupe from './internalDedupe'
import SmsAnalysis from './smsAnalysis'
import FinancialAnalysis from './finacialAnalysis'
import CrifAnalysis from './crifAnalysis'
import PopUp from '../../../presentationals/Popup/Popup.component'
import moment from 'moment'
import ApplicantLocation from '../CustomerPages/UserLocation.container'
import Loader from '../../../presentationals/Loader/DotLoader.component'
let jsondata = {}
let jsonSms = {}

const DetailsTab = ({crifDataApi, statementSource, allDetail, admin, kycdata, crifDataDashboard }) => {
    const [viewPage, setViewPage] = useState('kyc')
    const [personalData, setpersonalData] = useState('kyc')
    const [kycSubStateAAdhaar, setkycSubStateAAdhaar] = useState(true)
    const [aadhaarXmlData, setAadharXmlData] = useState({})
    const [chartDataState, setchartDataState] = useState([])
    const [notesText, setNotesText] = useState('')
    const [notesPopup, setNotesPopup] = useState(false)
    const [locationNotes, setLocationNotes] = useState(false)
    const [panNotes, setPanNotes] = useState('')
    const [aadhaarNotes, setAAdharNotesNotes] = useState('')
    const [notesType, setNotesType] = useState('')
    const [panState, setPanState] = useState(false)
    const [locationState, setLocationState] = useState(false)
    const [karzaData, setKarzadata] = useState({})
    const [chartDataStateLocation, setchartDataStateLocation] = useState([])
    const [underWriterStatus, setunderWriterStatus] = useState('')
    const [popupStatus, setpopupStatus] = useState('')
    const [newNotes, setNewNotes] = useState('')
    const [sidePoupState, setsidePoupState] = useState('')
    const [sideData, setKycDataSide] = useState('')
    const [smsJson, setSmsJson] = useState({})
    const [internlDedupeJson, setInternlDedupeJson] = useState({})
    const [finbitJson, setFinbitJson] = useState({})
    const [crifJson, setCrifJson] = useState({})
    const [kycDataJson, setKycDataSet] = useState({})
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [workingHistoryData, setWorkingData] = useState('')
    const [locationPopup, setLocationPoup] = useState(false)
    const [matchedData, setMatchedData] = useState({})
    const [amlState, setAmlState] = useState(false)
    const [loaderState, setLoader] = useState(false)
    const [amlData, setAmlData] = useState('')
    const [reportAml, setReportAml] = useState(false)
    const [matchedState, setmatchedState] = useState(false)
    const [addressDataApi, setAAdressData] = useState('')


    const pageHandler = (data) => {
        setViewPage(data)
    }
    const locationHandler = () => {
        setLocationPoup(true)

    }
    const MatchFace = () => {
        setLoader(true)
        matchFaceApi(allDetail.userId, callBack => {
            setLoader(false)

            if (callBack.error === null) {
                setMatchedData(callBack)

            } else {
                alert(callBack.error)
            }
        })
    }
    const mergeJson = () => {

        let data = {
            'crifData': crifJson,
            'finbitData': finbitJson,
            'dedupeData': internlDedupeJson,
            'smsData': smsJson,
            'kycData': jsondata,
            'userId': allDetail.userId,
            'loanId': allDetail.loanId,
            'empId': admin.emailId
        };
        setWorkingData(data)
        forceUpdate()
    }
    const saveData = () => {
        saveWorkingHistoryApi(workingHistoryData, callBack => {
            setWorkingData('')
            setpopupStatus('Points successfully saved !')
            setsidePoupState(true)
            removePopup()
        })
    }

    const personaDataApi = () => {
        getPersonalAnalysisReport(allDetail.userId, allDetail.loanId, callback => {
            let lt = parseFloat(callback.aadharAddressMatch)
            let data = [
                { name: '', value: Math.round(lt) },
                { name: '', value: (100 - Math.round(lt)) }
            ]
            setchartDataStateLocation(data)
            setpersonalData(callback)
        })
    }
    // const getAadhaarData = () => {
    //     aadharVerificationDetails(allDetail.userId, callBack => {
    //         setAadhaarData(callBack)

    //     })
    // }
    const aadharXmlDetailsData = () => {
        aadharXmlDetails(allDetail.userId, callBack => {
            setAadharXmlData(callBack)
            if (callBack && callBack.percentageOfMatch) {
                makegraphDataAadhar(callBack.percentageOfMatch)
                setAAdressData(JSON.parse(callBack.address))
            }
        })
    }
    const makegraphDataAadhar = (To) => {
        let data = [
            { name: '', value: parseFloat(To) },
            { name: '', value: (100 - parseFloat(To)) }
        ]
        setchartDataState(data)


    }
    const downloadFile = (filename, content) => {


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + content);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);

    }
    const commentChangeHandler = (e, type) => {
        if (e.target.value !== 'add') {
            setNotesText(e.target.value)
        }
        if (e.target.value === 'add') {
            setNotesPopup(true)
            setNotesText(e.target.value)

        }
        setNotesType(type)

    }
    useEffect(() => {
        jsondata = {}
        getSideData()
        personaDataApi()
        aadharXmlDetailsData()
        getpanProfileDetailsData()
        getPanNotesData()
        getAAdhaarNotes()
        getLocationNotes()
        getSavedFaceData()

    }, [])
    const getAml = () => {
        setLoader(true)
        getAmlData(allDetail.userId, callBack => {
            console.log(callBack)
            setAmlData(callBack)
            setLoader(false)

        })

    }
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            //   <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="">
            //     {`${(percent * 100).toFixed(0)}%`}
            //   </text>
            <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="12">{(chartDataState[0].value) + '%'}</tspan>
                {/* <tspan fontSize="14">{(percent * 100).toFixed(0)}</tspan> */}
            </text>
        );
    };
    const saveNotesType = () => {
        if (notesText !== '' && notesText !== undefined && notesText !== null) {
            saveCommentForCreditAssesment(allDetail.loanId, allDetail.userId, notesText, admin.emailId, notesType, callBack => {
                setNotesPopup(false)
                if (callBack === 'success' && notesType === 'pan') {
                    getNotes(allDetail.loanId, allDetail.userId, 'pan', callBack => {
                        setPanNotes(callBack)
                    })
                }
                if (callBack === 'success' && notesType === 'aadhaar') {
                    getNotes(allDetail.loanId, allDetail.userId, 'aadhaar', callBack => {
                        setAAdharNotesNotes(callBack)
                    })
                }
                if (callBack === 'success' && notesType === 'location') {
                    getNotes(allDetail.loanId, allDetail.userId, 'location', callBack => {
                        setLocationNotes(callBack)
                    })
                }
            })
        }
    }
    const kycTabHandle = (type) => {
        if (type === 'aadhar') {
            setkycSubStateAAdhaar(true)
            setPanState(false)
            setLocationState(false)
            setAmlState(false)
            setmatchedState(false)
        }
        if (type === 'pan') {
            setkycSubStateAAdhaar(false)
            setPanState(true)
            setLocationState(false)
            setAmlState(false)
            setmatchedState(false)
        }
        if (type === 'location') {
            setkycSubStateAAdhaar(false)
            setPanState(false)
            setLocationState(true)
            setAmlState(false)
            setmatchedState(false)
        }
        if (type === 'aml') {
            setkycSubStateAAdhaar(false)
            setPanState(false)
            setLocationState(false)
            setAmlState(true)
            getAml()
            setmatchedState(false)
        }
        if (type === 'face') {
            setkycSubStateAAdhaar(false)
            setPanState(false)
            setLocationState(false)
            setAmlState(false)
            setmatchedState(true)
        }

    }
    const getpanProfileDetailsData = (userId) => {
        panProfileDetails(allDetail.userId, callBack => {
            if (callBack !== null && callBack !== '' && callBack !== undefined) {
                setKarzadata(callBack)
            }
        })
    }
    const getPanNotesData = () => {
        getNotes(allDetail.loanId, allDetail.userId, 'pan', callBack => {
            setPanNotes(callBack)
        })
    }
    const closepopup = () => {
        setNotesPopup(false)
        setNotesText('')
        setNewNotes('')
        setLocationPoup(false)
    }
    const openCommentPopup = (data) => {
        setNotesPopup(true)
        setNotesType(data)
        getNotesnew(allDetail.loanId, allDetail.userId, data, callBack => {
            setNewNotes(callBack)
        })

    }
    const getAAdhaarNotes = () => {
        getNotes(allDetail.loanId, allDetail.userId, 'aadhar', callBack => {
            setAAdharNotesNotes(callBack)
        })
    }
    const getLocationNotes = () => {
        getNotes(allDetail.loanId, allDetail.userId, 'location', callBack => {
            setLocationNotes(callBack)
        })
    }
    const renderCustomizedLabelLocation = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            //   <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="">
            //     {`${(percent * 100).toFixed(0)}%`}
            //   </text>
            <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="12">{(chartDataStateLocation[0].value) + '%'}</tspan>
                {/* <tspan fontSize="14">{(percent * 100).toFixed(0)}</tspan> */}
            </text>
        );
    };
    const saveUnderWritter = (type) => {
        if (underWriterStatus !== '') {
            saveUnderWriterApi(type, allDetail, underWriterStatus, admin, callBack => {

                if (callBack === 'success') {
                    setunderWriterStatus('')
                    setpopupStatus('successfully saved !')
                    setsidePoupState(true)
                    removePopup()

                } else {
                    setpopupStatus('please try again later !')
                    setsidePoupState(true)
                    removePopup()
                }
            })
        } else {
            setpopupStatus('Please select value !')
            setsidePoupState(true)
            removePopup()
        }
    }
    const removePopup = () => {
        setTimeout(() => {
            setsidePoupState(false)
        }, 5000);
    }
    const underWriterHandler = (e) => {
        setunderWriterStatus(e.target.value)
    }
    const closepopupSide = () => {
        setsidePoupState(false)

    }
    const getSideData = () => {
        // if(kycdata!==''&&kycdata.aadharDecision){
        // setKycDataSide(kycdata)
        // }
        // kycAnalysisSideApi(allDetail.userId, callBack => {
        //     setKycDataSide(callBack)
        // })
    }
    const selectData = (mainkey, data, value) => {
        if (!jsondata) {
            jsondata = {};
        }

        const stringValue = String(value);

        if (jsondata.hasOwnProperty(mainkey)) {
            if (jsondata[mainkey].hasOwnProperty(data)) {
                if (jsondata[mainkey][data] === stringValue) {
                    delete jsondata[mainkey][data];
                } else {
                    jsondata[mainkey][data] = stringValue;
                }

                if (Object.keys(jsondata[mainkey]).length === 0) {
                    delete jsondata[mainkey];
                }
            } else {
                jsondata[mainkey][data] = stringValue;
            }
        } else {
            jsondata[mainkey] = {
                [data]: stringValue
            };
        }
        setKycDataSet(jsondata)

        mergeJson()


        // Return the modified jsondata or handle it as needed
        return jsondata;


    };
    const sendDataToMainPage = (data) => {

        setSmsJson(data)
        mergeJson()
    }
    const smsJsonData = (data, value) => {
        if (jsonSms.hasOwnProperty(data)) {
            const index = jsonSms[data].indexOf(value);
            if (index !== -1) {
                jsonSms[data].splice(index, 1);
            } else {
                jsonSms[data].push(value);
            }
        } else {
            jsonSms[data] = [value];
        }

        mergeJson()

    };
    const sendDataInternalDedupe = (data) => {
        setInternlDedupeJson(data)
        mergeJson()
    }
    const finbitJsonData = (data) => {
        setFinbitJson(data)
        mergeJson()
    }
    const sendDataToMainPageCrif = (data) => {
        setCrifJson(data)
        mergeJson()

    }
    const getSavedFaceData = () => {
        setLoader(true)
        getFaceData(allDetail.userId, callBack => {
            setMatchedData(callBack)
            setLoader(false)

        })
    }
    const viewAmlReport = () => {
        setReportAml(true)
    }
    const checkForPep = () => {
        setLoader(true)
        getAMlPEPDataApi(allDetail.userId, callBack => {
            console.log(callBack)
            // if (callBack === 'success') {
            getAml()
            // } else {
            //     setLoader(false)
            //     setpopupStatus('please try again later !')
            //     setsidePoupState(true)
            //     removePopup()
            // }

        })
    }
    return (
        <>
            {sidePoupState ?
                <PopUp popupStatus={popupStatus} closePopup={closepopupSide} />
                : ""}
            {loaderState ?
                <Loader />
                : ""}
            {reportAml ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px   " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => setReportAml(false)} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                        <embed src={`data:application/pdf;base64, ${amlData.report}`} type="application/pdf" width="100%" height="600px" />


                    </div>
                </div>
                : ""}
            {notesPopup ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px   " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                        {allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110' ?
                            <>


                                <div className="row from-group">
                                    <div className="col-xs-12" style={{ padding: '10px' }}>
                                        <h5 className="" style={{ marginBottom: '10px', marginTop: '10px', fontWeight: '700' }}><i style={{ fontWeight: '700' }} className='fa fa-commenting-o'></i>&nbsp;&nbsp; Comment</h5>
                                    </div>
                                </div>
                                <div style={{ background: '#F1F7FF', border: '1px solid #DBE9FF', padding: '10px', borderRadius: '12px' }}>
                                    Status : { }
                                    <hr />
                                    <textarea className='form-control' placeholder='type here...' onChange={(e) => commentChangeHandler(e, notesType)} />
                                    <div className='text-right'>
                                        <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                                    </div>
                                </div>
                            </> : ""}
                        {newNotes !== '' && newNotes.length > 0 ? <h5 style={{ fontWeight: '700' }}> Previous Comments</h5> : ""}
                        {newNotes !== '' && newNotes.length > 0 ?
                            <div style={{
                                borderRadius: '10px',
                                border: '1px solid #D1D9E2',
                                background: '#fff',
                                maxHeight: '200px',
                                overflowY: 'scroll'
                            }}>

                                {newNotes.map((data, i) => {
                                    return (
                                        <div className='container-fluid'>
                                            <div className='row' style={{ borderBottom: '1px solid #D1D9E2', padding: '10px' }} key={i}>
                                                <div className='col-sm-6 col-xs-6'>
                                                    {data.notes}<br />
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.empId}</span>
                                                </div>
                                                <div className='col-sm-6 col-xs-6 text-right'>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                                </div>

                                            </div>
                                            {/* <hr /> */}
                                        </div>
                                    )
                                })}
                            </div>
                            : <h4 className='text-center' style={{ marginTop: '10px' }}>No already saved Comments Found</h4>}

                    </div>
                </div>
                : ""}
            <div className='row full-height'>
                {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?
                    <h3 style={{ marginLeft: '15px' }}><b>Information</b> <button className='btn btn-primary' style={{ marginTop: '20px', right: '0', marginRight: '60px', position: 'fixed', top: '135px', zIndex: '1' }} onClick={() => saveData()}>Save Selected Points</button></h3>
                    : ""}

                <div className="btn-group btn-grp-credit-details" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', padding: '10px', fontSize: '14px' }}>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'kyc' ? '#A8C8FF' : '#fff', color: viewPage === 'kyc' ? '#000' : '#00000073', borderBottom: viewPage === 'kyc' ? '2px solid #267DFF' : '2px solid rgba(0, 0, 0, 0.25)', zIndex: 0 }} onClick={() => pageHandler('kyc')}> KYC Details</button>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'internal' ? '#A8C8FF' : '#fff', color: viewPage === 'internal' ? '#000' : '#00000073', borderBottom: viewPage === 'internal' ? '2px solid #267DFF' : '2px solid rgba(0, 0, 0, 0.25)', zIndex: 0 }} onClick={() => pageHandler('internal')}> Internal Dedupe</button>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'sms' ? '#A8C8FF' : '#fff', color: viewPage === 'sms' ? '#000' : '#00000073', borderBottom: viewPage === 'sms' ? '2px solid #267DFF' : '2px solid rgba(0, 0, 0, 0.25)', zIndex: 0 }} onClick={() => pageHandler('sms')}> SMS Analysis</button>
                    <button className="btn btn-outline-primary" disabled={statementSource !== '' ? false : true} style={{ background: viewPage === 'finacial' ? '#A8C8FF' : '#fff', color: viewPage === 'finacial' ? '#000' : '#00000073', borderBottom: viewPage === 'finacial' ? '2px solid #267DFF' : '2px solid rgba(0, 0, 0, 0.25)', zIndex: 0 }} onClick={() => pageHandler('finacial')}> Financial Details</button>
                    <button className="btn btn-outline-primary" style={{ background: viewPage === 'crif' ? '#A8C8FF' : '#fff', color: viewPage === 'crif' ? '#000' : '#00000073', borderBottom: viewPage === 'crif' ? '2px solid #267DFF' : '2px solid rgba(0, 0, 0, 0.25)', zIndex: 0 }} onClick={() => pageHandler('crif')}> CRIF Analysis</button>
                </div>
            </div>
            {viewPage === 'kyc' ?
                <Kyc
                    addressDataApi={addressDataApi}
                    checkForPep={checkForPep}
                    matchedState={matchedState}
                    reportAml={reportAml}
                    viewAmlReport={viewAmlReport}
                    amlData={amlData}
                    amlState={amlState}
                    matchedData={matchedData}
                    MatchFace={MatchFace}
                    locationHandler={locationHandler}
                    jsondata={kycDataJson}
                    selectData={selectData}
                    sideData={kycdata}
                    underWriterHandler={underWriterHandler}
                    saveUnderWritter={saveUnderWritter}
                    renderCustomizedLabelLocation={renderCustomizedLabelLocation}
                    chartDataStateLocation={chartDataStateLocation}
                    locationNotes={locationNotes}
                    openCommentPopup={openCommentPopup}
                    panNotes={panNotes}
                    allDetail={allDetail}
                    karzaData={karzaData}
                    kycTabHandle={kycTabHandle}
                    panState={panState}
                    locationState={locationState}
                    commentChangeHandler={commentChangeHandler}
                    saveNotesType={saveNotesType}
                    aadhaarNotes={aadhaarNotes}
                    admin={admin}
                    chartDataState={chartDataState}
                    aadhaarXmlData={aadhaarXmlData}
                    downloadFile={downloadFile}
                    kycSubStateAAdhaar={kycSubStateAAdhaar}
                    renderCustomizedLabel={renderCustomizedLabel}
                    personalData={personalData} />
                : ""}
            {viewPage === 'internal' ?

                <InternalDedupe internlDedupeJson={internlDedupeJson} sendDataInternalDedupe={sendDataInternalDedupe} allDetail={allDetail} admin={admin} />

                : ""}
            {viewPage === 'sms' ?
                <SmsAnalysis smsJsonData={smsJsonData} smsJson={smsJson} sendDataToMainPage={sendDataToMainPage} allDetail={allDetail} admin={admin} />
                : ""}
            {viewPage === 'finacial' ?
                <FinancialAnalysis statementSource={statementSource} finbitJsonData={finbitJsonData} finbitJson={finbitJson} allDetail={allDetail} admin={admin} />
                : ""}
            {viewPage === 'crif' ?
                <CrifAnalysis crifDataApi={crifDataApi} crifDataDashboard={crifDataDashboard} sendDataToMainPageCrif={sendDataToMainPageCrif} crifJson={crifJson} allDetail={allDetail} admin={admin} />
                : ""}
            {locationPopup ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red', cursor: 'pointer' }} className="fa fa-times-circle"></i>
                        <ApplicantLocation allDetail={allDetail} />
                    </div>

                </div>
                : ""}

        </>
    )
}
export default DetailsTab