import React, { useState } from 'react'
import DebtManagement from './DebtManagement'
import DebtManagementDashboard from './DebtManagementDashboard'
import KPIDebtManagementDashboard from './KPIDebtManagementDashboard'
import KPILoanManagementDashboard from './KPILoanManagementDashboard'
import LoanDashboard from './LoanDashboard'
import LoanPortfolioReview from './LoanPortfolioReview'
import PaymentsCollected from './PaymentsCollected'
import RevenueOperationalExpenses from './RevenueOperationalExpenses'
import BackLogo from '../../../../images/rewampimages/Chevron.png'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
export default function DebtListMain({pageViewHandler}) {
    const [activeTab, setActiveTab] = useState('debtManagement')

    const handleTabs = (type) => {
        setActiveTab(type)
    }
    return (
        <>
            <div className="DebtListMain">
                <div className="tabs">
                    <div className="row">
                        <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'debtManagement' ? 'active' : ''}`} onClick={() => handleTabs('debtManagement')}>
                            <h5>Debt Management</h5>
                        </div>
                        <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'KPIDebtManagement' ? 'active' : ''}`} onClick={() => handleTabs('KPIDebtManagement')}>
                            <h5>KPI Dashboard for Debt Managment</h5>
                        </div>
                        <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'DebtManagmentDashboard' ? 'active' : ''}`} onClick={() => handleTabs('DebtManagmentDashboard')}>
                            <h5>Debt Management Dashboard</h5>
                        </div>
                        <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'KPILoanManagmentDashboard' ? 'active' : ''}`} onClick={() => handleTabs('KPILoanManagmentDashboard')}>
                            <h5>KPI Loan Managament Dashboard</h5>
                        </div>
                        <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'Paymentscollected' ? 'active' : ''}`} onClick={() => handleTabs('Paymentscollected')}>
                            <h5>Payments collected</h5>
                        </div>
                        <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'RevenueOperation' ? 'active' : ''}`} onClick={() => handleTabs('RevenueOperation')}>
                            <h5>Revenue & Operational Expenses</h5>
                        </div>
                        <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'LoanPortfolio' ? 'active' : ''}`} onClick={() => handleTabs('LoanPortfolio')}>
                            <h5>Loan Portfolio Review</h5>
                        </div>
                        <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'LoanDashboard' ? 'active' : ''}`} onClick={() => handleTabs('LoanDashboard')}>
                            <h5>Loan Dashboard</h5>
                        </div>
                    </div>
                </div>

                <div className="Sections">
                    <div className="row">
                    <div className="col-sm-4 " style={{ marginTop: '15px',marginBottom:'30px' }}>
                            {/* <img onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e, 'landingPage')} >Back</button> */}
                            <button style={{ color: '#223d64', fontWeight: '400' ,display:'flex',border:'1px solid #267dff',borderRadius:'6px',padding:'8px 10px'}} onClick={(e) => pageViewHandler(e, 'landingPage')} ><ArrowBackIosNewIcon style={{marginTop:'1px'}}/>&nbsp;Back</button>
                        </div>
                    </div>
                    {
                        activeTab == 'debtManagement' ?
                            <DebtManagement />
                            : ''
                    }
                    {
                        activeTab == 'KPIDebtManagement' ?
                            <KPIDebtManagementDashboard />
                            : ''
                    }
                    {
                        activeTab == 'DebtManagmentDashboard' ?
                            <DebtManagementDashboard />
                            : ''
                    }
                    {
                        activeTab == 'KPILoanManagmentDashboard' ?
                            <KPILoanManagementDashboard />
                            : ''
                    }
                    {
                        activeTab == 'Paymentscollected' ?
                            <PaymentsCollected />
                            : ''
                    }
                    {
                        activeTab == 'RevenueOperation' ?
                            <RevenueOperationalExpenses />
                            : ''
                    }
                    {
                        activeTab == 'LoanPortfolio' ?
                            <LoanPortfolioReview />
                            : ''
                    }
                    {
                        activeTab == 'LoanDashboard' ?
                            <LoanDashboard />
                            : ''
                    }
                </div>
            </div>
        </>
    )
}
