import React from 'react'
import { STRING } from '../../../../utils/Constant'

const userStatus = ({ selectStatusHandler, statusHandler, allDetail, allStatus, userDetails, admin }) => (
    <div className="container-fluid  details-new">
        <div className="row">

            <div className="" style={{ marginTop: '10px' }}>
                {/* <label className="font-12px blue-text">Set Status</label> */}
                {/* {admin.role === 'superuser' || admin.emailId === 'accounts@phocket.in' ? */}
                {admin.role === 'superuser' ?

                    <select className="form-control" style={{ background: 'rgb(241, 247, 255)', color: '#000' }} onChange={(e) => selectStatusHandler(e)} defaultValue={userDetails !== undefined ? userDetails.statusId : ""}>
                        {/* defaultValue={userDetails !== undefined ? userDetails.statusId : ""} */}
                        <option value='' >Change Status</option>
                        {allStatus != null && allStatus != undefined && allStatus != '' ?
                            allStatus.map((status, i) => {
                                if (status.statusId != STRING.SELECT_ALL && status.statusId != STRING.SOFT_APPROVAL) {
                                    if (allDetail.statusId == STRING.PAYMENT_OVERDUE && status.adminStatus != 'Cancelled' && status.adminStatus != 'Rejected' && status.adminStatus != 'Approved' && status.adminStatus != 'Disbursed' && status.adminStatus != 'Paid' && status.adminStatus != 'Permanent Reject' && status.adminStatus != 'Incomplete Application' && status.adminStatus != 'Documents Collected') {
                                        return (

                                            <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                        );
                                    } else {
                                        if (status.statusId != STRING.CHEQUE_BOUNCE && status.adminStatus != 'Cancelled' && status.adminStatus != 'Rejected' && status.adminStatus != 'Approved' && status.adminStatus != 'Disbursed' && status.adminStatus != 'Paid' && status.adminStatus != 'Permanent Reject' && status.adminStatus != 'Incomplete Application' && status.adminStatus != 'Documents Collected') {
                                            return (
                                                <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                            );
                                        }
                                    }
                                }
                            }) : ""}
                    </select>
                    // : admin.role !== 'superuser' || admin.emailId !== 'accounts@phocket.in' ?
                    : admin.role !== 'superuser' && admin.emailId !== 'accounts@phocket.in' ?

                        <React.Fragment>

                            <select style={{ background: 'rgb(241, 247, 255)', color: '#000' }} disabled={(userDetails.applicationStatus === 'New Lead' || userDetails.applicationStatus === 'Communicated' || userDetails.applicationStatus === 'Loan Under Process' || userDetails.applicationStatus === 'Approved') ? false : true} className="form-control" onChange={(e) => selectStatusHandler(e)} defaultValue={userDetails !== undefined ? userDetails.statusId : ""}>
                                <option value='' >Change Status</option>
                                {allStatus != null && allStatus != undefined && allStatus != '' ?
                                    allStatus.map((status, i) => {
                                        if (status.adminStatus === 'New Lead' || status.adminStatus === 'Pending' || status.adminStatus === 'Communicated' || status.adminStatus === 'Expired' || status.adminStatus === 'Loan Under Process') {
                                            return (

                                                <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                            )
                                        }
                                    }) : ""}




                                {/* {allStatus != null && allStatus != undefined && allStatus != '' ?
                                    allStatus.map((status, i) => {
                                        if (status.statusId != STRING.SELECT_ALL && status.statusId != STRING.SOFT_APPROVAL) {
                                            if (allDetail.statusId == STRING.PAYMENT_OVERDUE && status.adminStatus != 'Cancelled' && status.adminStatus != 'Rejected' && status.adminStatus != 'Approved' && status.adminStatus != 'Disbursed' && status.adminStatus != 'Paid' && status.adminStatus != 'Permanent Reject' && status.adminStatus != 'Incomplete Application' && status.adminStatus != 'Documents Collected') {
                                                return (

                                                    <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                                );
                                            } else {
                                                if (status.statusId != STRING.CHEQUE_BOUNCE && status.adminStatus != 'Cancelled' && status.adminStatus != 'Rejected' && status.adminStatus != 'Approved' && status.adminStatus != 'Disbursed' && status.adminStatus != 'Paid' && status.adminStatus != 'Permanent Reject' && status.adminStatus != 'Incomplete Application' && status.adminStatus != 'Documents Collected') {
                                                    return (
                                                        <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                                    );
                                                }
                                            }
                                        }
                                    }) : ""} */}
                            </select>

                        </React.Fragment>
                        : admin.emailId === 'accounts@phocket.in' ?
                            <React.Fragment>

                                <select style={{ background: 'rgb(241, 247, 255)', color: '#000' }} disabled={(userDetails.applicationStatus === 'Payment Overdue' || userDetails.applicationStatus === 'Paid'|| userDetails.applicationStatus === 'Ongoing' ) ? false : true} className="form-control" onChange={(e) => selectStatusHandler(e)} defaultValue={userDetails !== undefined ? userDetails.statusId : ""}>
                                    <option value='' >Change Status</option>

                                    {admin.emailId === 'accounts@phocket.in' && allStatus != null && allStatus != undefined && allStatus != '' ?
                                        allStatus.map((status, i) => {
                                            if (status.adminStatus === 'Paid' || status.adminStatus === 'Payment Overdue'|| status.adminStatus === 'Ongoing') {
                                                return (
                                                    <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                                )
                                            }
                                        }) : ""}




                                </select>

                            </React.Fragment>
                            : ""}
                <div className=''>
                    <button onClick={() => statusHandler()} className='btn btn-primary' style={{ marginTop: '20px' }}>Save</button>
                </div>
            </div>
        </div>
    </div>
)

export default userStatus;
