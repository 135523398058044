import { Callbacks } from 'jquery'
import React, { useState, useEffect } from 'react'
import { calculateEmiApi, SaveUpdateLoanUtility } from '../../AdminActionCreator.component'
import PopUp from "../../../../presentationals/Popup/Popup.component";
import Loader from '../../../../presentationals/Loader/Loader.component';
import { APIS } from '../../../../../utils/api-factory'
import moment from 'moment';
export default function AddEditLendor({ addType, editData, handleSavebtn }) {
    const PageType = (Object.keys(editData)?.length > 0 ? 'Edit' : '')
    const [loader, setloader] = useState(false)
    const [popupstate, setPopupState] = useState(false)
    const [popupStatus, setpopupStatus] = useState('')
    const [nbfcList, setnbfcList] = useState([]);
    const [roiChange, setroiChange] = useState(false);
    const [roiNewValue, setRoiNewValue] = useState('')

    const [data, setData] = useState({
        nbfcId: '',
        roiReflectDate: '',
        previouslyAffect: 'true',
        stampDutyCharge: '',
        penaltyInterest: '',
        penaltyAmount: '',
        normalInterest: '',
        emiPenaltyInterest: '',
        gstInterest: '',
        chequeBounceCharge: '',
        mandateBounceCharge: '',
        gstIncluded: true,
        interestType: '',
        emiType: '',
        productId: '',
        productName: '',
        minAmount: '',
        maxAmount: '',
        active: false,
        tenure: ''
    })

    useEffect(() => {
        let date = editData?.roiReflectDate ? (editData.roiReflectDate).split(' ')[0] : ''
        let formattedDate = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
        console.log(moment(formattedDate).format('DD-MM-YYYY'))
        getNbfcList()
        if (Object.keys(editData)?.length > 0) {
            setData({
                nbfcId: editData.nbfcId,
                roiReflectDate: formattedDate,
                previouslyAffect: editData.previouslyAffect,
                stampDutyCharge: editData.stampDutyCharge,
                penaltyInterest: editData.penaltyInterest,
                penaltyAmount: editData.penaltyAmount,
                normalInterest: editData.normalInterest,
                emiPenaltyInterest: editData.emiPenaltyInterest,
                gstInterest: editData.gstInterest,
                chequeBounceCharge: editData.chequeBounceCharge,
                mandateBounceCharge: editData.mandateBounceCharge,
                gstIncluded: editData.gstIncluded,
                interestType: editData.interestType,
                emiType: editData.emiType,
                productId: editData.productId,
                productName: editData.productName,
                active: editData.active,
                minAmount: editData.minAmount,
                maxAmount: editData.maxAmount,
                id: editData.id,
                tenure: editData.tenure

            })
        }
    }, [editData])

    const handleEditableDate = (inputDate) => {
        if (PageType == 'Edit') {
            const [datePart, timePart] = inputDate.split(' ');
            const [day, month, year] = datePart.split('-').map(Number);

            const date = new Date(year, month - 1, day);

            const outputYear = date.getFullYear();
            const outputMonth = (date.getMonth() + 1).toString().padStart(2, '0');
            const outputDay = date.getDate().toString().padStart(2, '0');

            return `${outputYear}-${outputMonth}-${outputDay}`;
        }
    }


    const handlechange = (e) => {
        console.log(e.target.name, '-', e.target.value)
        const { name, value } = e.target;
        console.log(e.target.value)
        if (e.target.name === 'roiReflectDate') {
            setroiChange(true)
            setRoiNewValue(e.target.value)
        }
        setData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handlesave = (type) => {
        // console.log(editData.roiReflectDate.split(' ')[0])
        // console.log(moment(roiNewValue).format('DD-MM-YYYY'))
        if (type == 'update') {
            setloader(true)
            data.id = editData.id
            data.roiReflectDate = roiChange ? moment(roiNewValue).format('DD-MM-YYYY') : (editData.roiReflectDate).split(' ')[0];
            SaveUpdateLoanUtility(data, (Callback) => {
                setloader(false)
                if (Callback == 'success') {
                    setPopupState(true)
                    setpopupStatus('Updated Successfully')
                    setTimeout(() => {
                        handleSavebtn()
                    }, 1000)
                } else {
                    setPopupState(true)
                    setpopupStatus('Error')
                }
            })
        } else if (type == 'save') {
            setloader(true)
            data.roiReflectDate = roiChange ? moment(roiNewValue).format('DD-MM-YYYY') : (editData.roiReflectDate).split(' ')[0];
            console.log(data)
            SaveUpdateLoanUtility(data, (Callback) => {
                setloader(false)
                if (Callback == 'success') {
                    setPopupState(true)
                    setpopupStatus('Saved Successfully')
                    setTimeout(() => {
                        handleSavebtn()
                    }, 1000)
                } else {
                    setPopupState(true)
                    setpopupStatus('Error')
                }
            })
        }
        else if (type == 'back') {
            handleSavebtn()
        }
    }


    const closePopup = () => {
        setPopupState(false)
    }

    const getNbfcList = () => {
        fetch(APIS.GET_LIST_OF_NBFC)
            .then(res => res.json())
            .then(res => {
                setnbfcList(res)
            })
    }

    return (
        <>
            <div className="AddEditExecutor">
                <button style={{ marginTop: '20px' }} className='btn btn-primary' onClick={() => handlesave('back')}>Back</button>

                {
                    loader ?
                        <Loader />
                        : ''}
                {
                    popupstate ?
                        <PopUp closePopup={closePopup} popupStatus={popupStatus} />
                        : ''}

                <h3 className='heading'>{PageType}</h3>

                <div className="forms">
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="nbfcId">NBFC Name</label>
                                {/* <input className='inputtag' type="text" id="nbfcId" placeholder='NBFC Id' name="nbfcId" value={data?.nbfcId} onChange={(e) => handlechange(e)} /> */}
                                <select disabled={addType === 'edit' ? true : false} value={data?.nbfcId} onChange={(e) => handlechange(e)} id="nbfcId" className='inputtag' name="nbfcId">
                                    <option value="">Select NBFC</option>
                                    {
                                        nbfcList && nbfcList?.length > 0 ?
                                            nbfcList?.map((value, index) => {
                                                return (
                                                    <option value={value?.nbfcId} key={index}>{value?.name}</option>
                                                )
                                            })
                                            : ''}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="roiReflectDate">ROI Reflect Date</label>
                                <input className='inputtag' type="date" id="roiReflectDate" placeholder='ROI Reflect Date' name="roiReflectDate" value={data?.roiReflectDate} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="previouslyAffect">Previously Affect</label>
                                <select id="previouslyAffect" className='inputtag' name="previouslyAffect" value={data?.previouslyAffect} onChange={(e) => handlechange(e)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="stampDutyCharge">stamp Duty Charge</label>
                                <input className='inputtag' type="number" id="stampDutyCharge" placeholder='Stamp Duty Charge' name="stampDutyCharge" value={data?.stampDutyCharge} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="minAmount">Min Amount</label>
                                <input className='inputtag' type="number" id="minAmount" placeholder='Min Amount' name="minAmount" value={data?.minAmount} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="maxAmount">Max Amount</label>
                                <input className='inputtag' type="number" id="maxAmount" placeholder='Max Amount' name="maxAmount" value={data?.maxAmount} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="penaltyInterest">Penalty Interest</label>
                                <input className='inputtag' type="number" id="penaltyInterest" placeholder='Penalty Interest' name="penaltyInterest" value={data?.penaltyInterest} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="penaltyAmount">Penalty Amount</label>
                                <input className='inputtag' type="number" id="penaltyAmount" placeholder='Penalty Amount' name="penaltyAmount" value={data?.penaltyAmount} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="normalInterest">Normal Interest</label>
                                <input className='inputtag' type="number" id="normalInterest" placeholder='Normal Interest' name="normalInterest" value={data?.normalInterest} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="emiPenaltyInterest">EMI Penalty Interest</label>
                                <input className='inputtag' type="number" id="emiPenaltyInterest" placeholder='EMI Penalty Interest' name="emiPenaltyInterest" value={data?.emiPenaltyInterest} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="gstInterest">GST Interest</label>
                                <input className='inputtag' type="number" id="gstInterest" placeholder='GST Interest' name="gstInterest" value={data?.gstInterest} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="chequeBounceCharge">cheque Bounce Charge</label>
                                <input className='inputtag' type="number" id="chequeBounceCharge" placeholder='cheque Bounce Charge' name="chequeBounceCharge" value={data?.chequeBounceCharge} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="mandateBounceCharge">Mandate Bounce Charge</label>
                                <input className='inputtag' type="number" id="mandateBounceCharge" placeholder='Mandate Bounce Charge' name="mandateBounceCharge" value={data?.mandateBounceCharge} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="interestType">Interest Type</label>
                                <select name="interestType" className='inputtag' id="interestType" value={data?.interestType} onChange={(e) => handlechange(e)}>
                                    <option value="">Select Value</option>
                                    <option value='Flat'>Flat</option>
                                    <option value='Reducing'>Reducing</option>

                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="gstIncluded">GST Included</label>
                                <select name="gstIncluded" className='inputtag' id="gstIncluded" value={data?.gstIncluded} onChange={(e) => handlechange(e)}>
                                    <option>Select Value</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="emiType">EMI Type</label>
                                <select name="emiType" className='inputtag' id="emiType" value={data?.emiType} onChange={(e) => handlechange(e)}>
                                    <option value="">Select Value</option>
                                    <option value='Weekly'>Weekly</option>
                                    <option value='Monthly'>Monthly</option>
                                    <option value='Fortnightly'>Fortnightly</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="productId">Product Id</label>
                                <input type="text" readOnly name="productId" className='inputtag' id="productId" value={data?.productId} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="productName">Product Name</label>
                                <input type="text" name="productName" className='inputtag' id="productName" value={data?.productName} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="active">is Active</label>
                                <select name="active" id="active" className='inputtag' value={data?.active} onChange={(e) => handlechange(e)}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div className="formgrp newmargin">
                                <label htmlFor="tenure">Tenure (In Months)</label>
                                <input type="text" name="tenure" className='inputtag' id="tenure" value={data?.tenure} onChange={(e) => handlechange(e)} />
                            </div>
                        </div>

                    </div>


                    <div className='savebtn'>

                        <button className='btn' onClick={() => handlesave(PageType == 'Edit' ? 'update' : 'save')}>{PageType == 'Edit' ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>

        </>
    )
}
