import React, { useEffect, useState } from "react";
import { getAllBusinessTargetSubCategory } from "../../targetactioncreator";

const SubCategoryList = ({ editHandler, ActiveMonthFilter }) => {
    // const expenseData = {
    //     expenseId: "EXP12345",
    //     categoryId: "CAT67890",
    //     subCategoryId: "SUB12345",
    //     subCategoryType: "Office Supplies",
    //     subCategoryDescription: "Purchase of office supplies including pens, paper, and notebooks",
    //     isActive: true,
    //     expenseCycle: "Monthly",
    //     id: "2"
    // };
    const [expenseData, setExepensedata] = useState('')

    useEffect(() => {
        getAllBusinessTargetSubCategory(callback => {
            setExepensedata(callback.data)
        })

    }, [ActiveMonthFilter])
    const handleDelete = () => {
    };

    return (
        <div className="container Executor">
            <div
                style={{
                    background: '#f2f2f2',
                    padding: '10px',
                    margin: '50px auto 0',
                    borderRadius: '5px',
                    overflow: 'auto',
                    maxWidth: '1000px'
                }}
            >
                <div>
                    <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                {/* <th>Category Id</th> */}
                                <th>Sub Category Type</th>
                                <th>Sub Category Description</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {expenseData !== '' && expenseData.length > 0 ? expenseData.map((data, i) => {
                                return (
                                    <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                        <td>{data.name}</td>
                                        {/* <td>{data.categoryId}</td> */}
                                        <td>{data.subCategoryType}</td>
                                        <td>{data.subCategoryDescription}</td>
                                        <td>
                                            <i
                                                className="fa fa-pencil-square-o fa-lg"
                                                aria-hidden="true"
                                                onClick={() => editHandler(data)}
                                                style={{ cursor: 'pointer' }}
                                                aria-label="Edit Expense"
                                                title="Edit Expense"
                                            ></i>
                                        </td>
                                        <td>
                                            <i
                                                className="fa fa-trash-o fa-lg"
                                                aria-hidden="true"
                                                onClick={() => handleDelete(data)}
                                                style={{ cursor: 'pointer' }}
                                                aria-label="Delete Expense"
                                                title="Delete Expense"
                                            ></i>
                                        </td>
                                    </tr>
                                )
                            }) : ""}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SubCategoryList;
