import React, { useEffect, useState } from "react";
import Popup from '../../../../presentationals/Popup/Popup.component';
import { saveExpenseData } from "../targetactioncreator";

const EditExpence = ({ editData }) => {
    const [employeeData, setEmployeeData] = useState({
        expenseAmount: '',
        updatedBy: "",
        gstAmount: 0,
        isGSTInclusive: true,
        isVerified: false,
        verifiedBy: ""
    });

    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [errors, setErrors] = useState({});

    const closePopup = () => {
        setPopup(false);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!employeeData.expenseAmount) {
            formErrors.expenseAmount = "Expense Amount is required";
            isValid = false;
        }
        if (!employeeData.updatedBy) {
            formErrors.updatedBy = "Updated By is required";
            isValid = false;
        }
        if (!employeeData.gstAmount && employeeData.isGSTInclusive) {
            formErrors.gstAmount = "GST Amount is required if GST is inclusive";
            isValid = false;
        }
        if (!employeeData.verifiedBy) {
            formErrors.verifiedBy = "Verified By is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Expense Data Saved", employeeData);
            saveExpenseData(employeeData, callback => {
                if (callback.message === 'success') {
                    setPopup(true);
                    setPopupStatus('Data Saved Successfully!');
                } else {
                    setPopup(true);
                    setPopupStatus('Please try again later!');
                }
                removePopup();
            });
        }
    };

    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    useEffect(() => {
        if (editData.id) {
            setEmployeeData({
                expenseAmount: editData.expenseAmount || "",
                updatedBy: editData.updatedBy || "",
                gstAmount: editData.gstAmount || "",
                isGSTInclusive: editData.isGSTInclusive || false,
                isVerified: editData.isVerified || false,
                verifiedBy: editData.verifiedBy || '',
                id: editData.id || '',
            });
        }
    }, [editData]);

    return (
        <div className="container">
            {sidePopup && <Popup popupStatus={popupStatus} closePopup={closePopup} />}
            <div className="target-box">
                <h3>Add/Update Expense Information</h3>
                <hr style={{ borderTop: '1px solid #000' }} />
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Expense Amount</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="expenseAmount"
                                        value={employeeData.expenseAmount}
                                        onChange={handleChange}
                                    />
                                    {errors.expenseAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.expenseAmount}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Updated By</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="updatedBy"
                                        value={employeeData.updatedBy}
                                        onChange={handleChange}
                                    />
                                    {errors.updatedBy && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.updatedBy}</span>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>GST Amount</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="number"
                                        name="gstAmount"
                                        value={employeeData.gstAmount}
                                        onChange={handleChange}
                                    />
                                    {errors.gstAmount && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.gstAmount}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>GST Inclusive</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="isGSTInclusive"
                                        checked={employeeData.isGSTInclusive}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Verified</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid', marginTop: '15px' }}
                                        type="checkbox"
                                        name="isVerified"
                                        checked={employeeData.isVerified}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <div style={{ width: '50%' }}>
                                    <label style={{ marginTop: '10px' }}>Verified By</label>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <input
                                        style={{ background: '#fff', border: '1px solid' }}
                                        className="form-control"
                                        type="text"
                                        name="verifiedBy"
                                        value={employeeData.verifiedBy}
                                        onChange={handleChange}
                                    />
                                    {errors.verifiedBy && (
                                        <span style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{errors.verifiedBy}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        <button type="submit" className="btn btn-primary" >Save Expense Data</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditExpence;
