import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Nodata from '../../../../images/nodata.png'
import 'react-loading-skeleton/dist/skeleton.css'
import { newsimilarProfileRedux, saveUnderWriterApi } from "../AdminActionCreator.component";
import { Pagination } from "@mui/material";
import { getNotes, getNotesnew, saveCommentForCreditAssesment, similarProfileData } from "../Analysis/analysisactionCreator";
import KycImageActive from './images/placeholder Fill (4).png'
import LocationInactive from './images/locationInactive.png'
import PhoneInactive from './images/phoneinactive.png'
import AccountInactive from './images/accountinactive.png'
import Kycinactive from './images/placeholder Fill (10).png'
import LocationActive from './images/placeholder Fill (11).png'
import PhoneActive from './images/placeholder Fill (12).png'
import AccountActive from './images/placeholder Fill (13).png'
import PopUp from '../../../presentationals/Popup/Popup.component'
import moment from "moment";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

let paginationNumber = 10
let similarProfileDataJson = {}
const InternalDedupe = (props) => {
    let allDetail = props.allDetail
    let admin = props.admin
    let sendDataInternalDedupe = props.sendDataInternalDedupe
    let internlDedupeJson = props.internlDedupeJson
    const [fraudData, setFraudData] = useState([])
    const [loaderState, setloaderState] = useState(false)
    const [tableMinPagination, settableMinPagination] = useState(0)
    const [tableMaxPagination, settableMaxPagination] = useState(paginationNumber)
    const [pageNumber, setpageNumber] = useState(1)
    const [matchedSimilarData, setmatchedSimilarData] = useState([])
    const [filterType, setFilterType] = useState('detailed')
    const [allSimilarProfileData, setallSimilarProfileData] = useState('')
    const [filterData, setFilterData] = useState('')
    const [underWriterStatus, setunderWriterStatus] = useState('')
    const [popupStatus, setpopupStatus] = useState('')
    const [sidePoupState, setsidePoupState] = useState('')
    const [newNotes, setnewNotes] = useState('')
    const [notesType, setNotesType] = useState('')
    const [notesPopup, setNotesPopup] = useState(false)
    const [notesText, setNotesText] = useState('')
    const [loanData, setLoanData] = useState('')

    const internalsimilarProfileDataJson = (data, value) => {
        if (filterType !== '') {
            if (similarProfileDataJson.hasOwnProperty(data)) {
                const index = similarProfileDataJson[data].indexOf(value);
                if (index !== -1) {
                    similarProfileDataJson[data].splice(index, 1);
                } else {
                    similarProfileDataJson[data].push(value);
                }
            } else {
                similarProfileDataJson[data] = [value];
            }

            sendDataInternalDedupe(similarProfileDataJson)
        }
    };

    const similarProfileDataApiData = () => {
        similarProfileData(allDetail.loanId, callback => {
            setmatchedSimilarData(callback.matchBy)
            setLoanData(callback)
        })
    }
    const commentChangeHandler = (e, type) => {
        if (e.target.value !== 'add') {
            setNotesText(e.target.value)
        }
        if (e.target.value === 'add') {
            setNotesPopup(true)
            setNotesText(e.target.value)

        }
        setNotesType(type)

    }
    const saveNotesType = () => {
        if (notesText !== '' && notesText !== undefined && notesText !== null) {
            saveCommentForCreditAssesment(allDetail.loanId, allDetail.userId, notesText, admin.emailId, filterType, callBack => {
                setNotesPopup(false)
                if (callBack === 'success') {
                    getNotesnew(allDetail.loanId, allDetail.userId, data, callBack => {
                        setnewNotes(callBack)
                    })
                }

            })
        }
    }
    const getdata = () => {
        if (props.internalDedupeData.userId === allDetail.userId) {

            let last = []
            Object.keys(props.internalDedupeData).length > 0 ?
                Object.keys(props.internalDedupeData).map(data => {
                    last.push(props.internalDedupeData[data])
                    setallSimilarProfileData(last)
                    setFilterData(last)
                    setFraudData(last)

                }) : null

        } else {

            let alldata = {
                'userId': allDetail.userId,
                'loanId': allDetail.loanId,
                'regenerateParam': 0
            }
            let matchByCategory = []
            setloaderState(true)
            // newsimilarProfileRedux(alldata,
            props.newsimilarProfileRedux(alldata, callback => {

                setloaderState(false)
                let last = []
                Object.keys(callback).length > 0 ?
                    Object.keys(callback).map(data => {
                        last.push(callback[data])
                        setallSimilarProfileData(last)
                        setFilterData(last)
                        setFraudData(last)

                    }) : null

            })
        }
    }
    const phocketTablePagination = (data, type) => {
        if (type == 'back') {
            if (tableMinPagination >= paginationNumber) {
                settableMinPagination(tableMinPagination - paginationNumber)
                settableMaxPagination(tableMaxPagination - paginationNumber)


            }
        } else if (type == 'forward') {
            if (tableMaxPagination < data.length) {
                settableMinPagination(tableMinPagination + paginationNumber)
                settableMaxPagination(tableMaxPagination + paginationNumber)

            }
        }
    }
    const handleChangeNewPagination = (e, value) => {
        setpageNumber(value)
        const newMinPagination = (value - 1) * paginationNumber;
        const newMaxPagination = value * paginationNumber;

        settableMaxPagination(newMaxPagination)
        settableMinPagination(newMinPagination)

    };
    const similarFilterData = (data) => {
        // setViewPage('similar')
        settableMinPagination(0)
        settableMaxPagination(10)
        // setSmsTab('')

        let d = []
        let similarProfileData = allSimilarProfileData
        if (data) {
            setFilterType(data)

            similarProfileData = similarProfileData.filter(row => {
                if (row.matchBy != null) {
                    if (row.matchBy.includes(data)) {

                        d.push(row)
                        setFilterData(d)
                        setFraudData(d)
                        // forceUpdate()
                    }
                }
            })
        }
        if (data === 'detailed') {
            setFraudData(allSimilarProfileData)
            setFilterType('detailed')
            // forceUpdate()
        }

    }
    useEffect(() => {
        getdata()
        similarProfileDataApiData()
        similarProfileDataJson = internlDedupeJson

    }, [])
    const saveUnderWritter = (type) => {
        let decisionType = filterType === 'name' ? 'kycData' : filterType === 'address' ? 'address' : filterType === 'mobileNo' ? 'mobileNumbers' : filterType === 'accountNo' ? 'accountNumbers' : ''
        if (underWriterStatus !== '' && decisionType !== '') {
            saveUnderWriterApi(decisionType, allDetail, underWriterStatus, admin, callBack => {

                if (callBack === 'success') {
                    setunderWriterStatus('')
                    setpopupStatus('successfully saved !')
                    setsidePoupState(true)
                    removePopup()

                } else {
                    setpopupStatus('please try again later !')
                    setsidePoupState(true)
                    removePopup()
                }
            })
        } else {
            setpopupStatus('Please select value !')
            setsidePoupState(true)
            removePopup()
        }
    }
    const removePopup = () => {
        setTimeout(() => {
            setsidePoupState(false)
        }, 5000);
    }
    const underWriterHandler = (e) => {
        setunderWriterStatus(e.target.value)
    }
    const closepopupSide = () => {
        setsidePoupState(false)

    }
    const openCommentPopup = (data) => {
        setNotesPopup(true)
        setNotesType(data)
        getNotesnew(allDetail.loanId, allDetail.userId, data, callBack => {
            setnewNotes(callBack)
        })

    }
    const closepopup = () => {
        setNotesPopup(false)
        setNotesText('')
        setnewNotes('')
    }
    const regenerateSp = () => {
        let alldata = {
            'userId': allDetail.userId,
            'loanId': allDetail.loanId,
            'regenerateParam': 1
        }
        let matchByCategory = []
        setloaderState(true)
        // newsimilarProfileRedux(alldata,
        props.newsimilarProfileRedux(alldata, callback => {

            setloaderState(false)
            let last = []
            Object.keys(callback).length > 0 ?
                Object.keys(callback).map(data => {
                    last.push(callback[data])
                    setallSimilarProfileData(last)
                    setFilterData(last)
                    setFraudData(last)

                }) : null

        })

    }
    return (
        <>
            {sidePoupState ?
                <PopUp popupStatus={popupStatus} closePopup={closepopupSide} />
                : ""}
            {notesPopup ?
                <div className="background-blur">

                    <div className="popup-main small-popup container-fluid max-width-500px   " style={{ padding: '20px', borderRadius: '10px' }}>
                        <i onClick={() => closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                        <div className="row from-group">
                            <div className="col-xs-12" style={{ padding: '10px' }}>
                                <h5 className="" style={{ marginBottom: '10px', marginTop: '10px', fontWeight: '700' }}><i style={{ fontWeight: '700' }} className='fa fa-commenting-o'></i>&nbsp;&nbsp; Comment</h5>
                            </div>
                        </div>
                        <div style={{ background: '#F1F7FF', border: '1px solid #DBE9FF', padding: '10px', borderRadius: '12px' }}>

                            <textarea className='form-control' placeholder='type here...' onChange={(e) => commentChangeHandler(e, notesType)} />
                            <div className='text-right'>
                                <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => saveNotesType()}>Save</button>
                            </div>
                        </div>
                        {newNotes !== '' && newNotes.length > 0 ? <h5 style={{ fontWeight: '700' }}> Previous Comments</h5> : ""}
                        {newNotes !== '' && newNotes.length > 0 ?
                            <div style={{
                                borderRadius: '10px',
                                border: '1px solid #D1D9E2',
                                background: '#fff',
                                maxHeight: '200px',
                                overflowY: 'scroll'
                            }}>

                                {newNotes.map((data, i) => {
                                    return (
                                        <div className='container-fluid'>
                                            <div className='row' style={{ borderBottom: '1px solid #D1D9E2', padding: '10px' }} key={i}>
                                                <div className='col-sm-6 col-xs-6'>
                                                    {data.notes}<br />
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.empId}</span>
                                                </div>
                                                <div className='col-sm-6 col-xs-6 text-right'>
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                                </div>

                                            </div>
                                            {/* <hr /> */}
                                        </div>
                                    )
                                })}
                            </div>
                            : ""}

                    </div>
                </div>
                : ""}
            <div className="cntainer-fluid details-body-credit full-height credit-module">

                <div className="row">
                    <div className="col-sm-3 col-xs-12">
                        <div className="credit-blue-box">
                            <div className="row">
                                {console.log(loanData)}
                                <div className="col-sm-6 col-xs-12"> Open Loan Found?</div>
                                <div className="col-sm-6 col-xs-12 text-right"><b style={{ color: loanData.openLoanFound ? 'red' : 'green' }}>{loanData.openLoanFound ? 'YES' : 'NO'}</b></div>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'name' ? KycImageActive : Kycinactive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => matchedSimilarData && matchedSimilarData.length > 0 && (matchedSimilarData.includes('aadhaar') || matchedSimilarData.includes('pan')) ? similarFilterData('name') : ''} style={{ fontSize: '14px', cursor: matchedSimilarData && matchedSimilarData.length > 0 && (matchedSimilarData.includes('aadhaar') || matchedSimilarData.includes('pan')) ? 'pointer' : 'not-allowed', color: filterType === 'name' ? '#2B78FF' : '#000', fontWeight: filterType === 'name' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'name' ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'name' ? '700' : '400' }}> KYC Match</span>
                                <br />
                                {matchedSimilarData && matchedSimilarData.length > 0 && (matchedSimilarData.includes('aadhaar') || matchedSimilarData.includes('pan')) ?
                                    <span style={{ color: '#F44', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                    <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'address' ? LocationActive : LocationInactive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('address') ? similarFilterData('address') : ''} style={{ fontSize: '14px', cursor: matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('address') ? 'pointer' : 'not-allowed', color: filterType === 'address' ? '#2B78FF' : '#000', fontWeight: filterType === 'address' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'address' ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'address' ? '700' : '400' }}> Address Match</span>
                                <br />
                                {matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('address') ?
                                    <span style={{ color: '#F44', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                    <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                <img src={filterType === 'mobileNo' ? PhoneActive : PhoneInactive} width={'32px'} />
                            </div>
                            <div className="col-sm-8" onClick={() => matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('mobileNo') ? similarFilterData('mobileNo') : ''} style={{ fontSize: '14px', cursor: matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('mobileNo') ? 'pointer' : 'not-allowed', color: filterType === 'mobileNo' ? '#2B78FF' : '#000', fontWeight: filterType === 'mobileNo' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'mobileNo' ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'mobileNo' ? '700' : '400' }}> Mobile Match</span>
                                <br />
                                {matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('mobileNo') ?
                                    <span style={{ color: '#F44', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                    <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4 text-right">
                                {/* <img src={AccountInactive} width={'32px'} /> */}
                                <img src={filterType === 'accountNo' ? AccountActive : AccountInactive} width={'32px'} />

                            </div>
                            <div className="col-sm-8" onClick={() => matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('accountNo') ? similarFilterData('accountNo') : ''} style={{ fontSize: '14px', cursor: matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('accountNo') ? 'pointer' : 'not-allowed', color: filterType === 'accountNo' ? '#2B78FF' : '#000', fontWeight: filterType === 'accountNo' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'accountNo' ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'accountNo' ? '700' : '400' }}> Account Match</span>
                                <br />
                                {matchedSimilarData && matchedSimilarData.length > 0 && matchedSimilarData.includes('accountNo') ?
                                    <span style={{ color: '#F44', background: '#FFEFF2', borderRadius: '4px', padding: '2px 10px', marginRight: '5px', fontSize: '11px', marginTop: '20px' }}>Found</span> :
                                    <span style={{ color: '#4CC78C', background: '#E1F6EC', borderRadius: '4px', padding: '2px', fontSize: '11px', marginRight: '5px', marginTop: '20px' }}>Not Found</span>}
                            </div>

                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-4">
                            </div>
                            <div className="col-sm-8" onClick={() => matchedSimilarData && matchedSimilarData.length > 0 ? similarFilterData('detailed') : ''} style={{ fontSize: '14px', cursor: matchedSimilarData && matchedSimilarData.length > 0 ? 'pointer' : 'not-allowed', color: filterType === 'detailed' ? '#2B78FF' : '#000', fontWeight: filterType === 'detailed' ? '700' : '400' }}>
                                <span style={{ color: filterType === 'detailed' ? '#267DFF' : 'rgba(0, 0, 0, 0.45)', fontWeight: filterType === 'detailed' ? '700' : '400' }}>  Detailed View</span>

                            </div>

                        </div>

                    </div>
                    <div className="col-sm-9 col-xs-12" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.25)', height: '600px', overflow: 'auto' }}>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-sm-6 col-xs-12">
                                <h4 style={{ color: 'rgba(0, 0, 0, 0.80)' }}><i class="fa fa-server" aria-hidden="true"></i>&nbsp;&nbsp;Internal Dedupe</h4>
                            </div>
                            <div className="col-sm-6 col-xs-12 text-right">
                                <button className="reset-btn" style={{ marginTop: '0' }} onClick={() => regenerateSp()}>Regenerate Similar Profiles</button>
                            </div>
                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-sm-6 col-xs-6"></div>
                            <div className="col-sm-6 col-xs-6">
                                {filterType !== 'detailed' ?
                                    <div style={{ float: 'inline-end', marginTop: '-15px' }} >
                                        <span onClick={() => openCommentPopup(filterType)} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>

                                        <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'aadhaar')} >
                                            <option value={''}>Select Option</option>
                                            <option value={'Found'}>Found</option>
                                            <option value={'Not Found'}>Not Found</option>

                                        </select>
                                        <button className="btn btn-primary" style={{ marginTop: '17px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter()}>Save</button>
                                    </div>
                                    : ""}
                            </div>

                        </div>
                        {fraudData && fraudData.length > 0 && loaderState === false ?
                            <div className='phocket-table-new' style={{ overflow: 'auto', marginTop: '0' }}>
                                <table className="text-center">
                                    <thead>
                                        <tr>
                                            <th>Application Id</th>
                                            <th>Name</th>
                                            <th>Email ID</th>
                                            <th>Loan Status</th>
                                            <th>Reject Reason</th>
                                            <th>Match By</th>
                                            <th>Priority</th>
                                            <th>Address</th>
                                            {/* <th>Info</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fraudData
                                            ? fraudData
                                                .slice(tableMinPagination, tableMaxPagination)
                                                .map((data, i) => {
                                                    if (!data) return null;

                                                    const messageType = data.applicationId;
                                                    const isEmiIncluded =
                                                        similarProfileDataJson[filterType] !== undefined &&
                                                        similarProfileDataJson[filterType].some(
                                                            (item) => item.applicationId === messageType
                                                        );

                                                    return (
                                                        <tr
                                                            key={i}
                                                            style={{
                                                                background: isEmiIncluded ? "lightgreen" : "white",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => internalsimilarProfileDataJson(filterType, data)}
                                                        >
                                                            <td>{data.applicationId}</td>
                                                            <td style={{ whiteSpace: "break-spaces" }}>{data.userName}</td>
                                                            <td>{data.emailId}</td>
                                                            <td style={{ background: data.loanStatus === 'Approved' || data.loanStatus === 'Disbursed' || data.loanStatus === 'Ongoing' || data.loanStatus === 'Received' || data.loanStatus === 'Payment Overdue' ? '#ecfcc1' : '' }}>{data.loanStatus}</td>
                                                            <td>{data.rejectReason}</td>
                                                            <td>{data.matchBy?.toString()}</td>
                                                            <td>{data.pri}</td>
                                                            <td>{data.address}</td>
                                                        </tr>
                                                    );
                                                })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {fraudData && fraudData.length > 0 && loaderState === false ?
                            <div className="container-fluid" style={{ marginBottom: '20px', marginTop: '20px', float: 'right' }}>

                                <Pagination color="primary"
                                    count={Math.ceil(fraudData.length / 10)}
                                    page={pageNumber} onChange={handleChangeNewPagination} />
                                {/* <div className="row">
                                <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block   " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(fraudData, 'back')}></button></div>
                                <div className="col-sm-2 col-xs-12  phoc text-center" >
                                    {(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of ' + fraudData.length}
                                </div>
                                <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " o style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(fraudData, 'forward')}></button></div>
                            </div> */}

                            </div>
                            : fraudData && fraudData.length > 0 && loaderState === false ? <div className='col-sm-12 col-xs-12 text-center'><img src={Nodata} style={{ width: '30%', marginTop: '20px' }} /></div> : ""}
                        {loaderState ?
                            <div className='phocket-table-new' style={{ overflow: 'auto' }} >
                                <table className='text-center  ' style={{ marginTop: '0px' }}>
                                    <thead>
                                        <tr>
                                            <th>Application Id</th>
                                            <th>Name</th>
                                            <th>Email ID</th>
                                            <th>Loan Status</th>
                                            <th>Reject Reason</th>
                                            <th>Match By</th>
                                            <th>Priority</th>
                                            <th>Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>

                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>
                                        <tr>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                            <td><Skeleton height={40} /></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            : ""}
                            {console.log(fraudData)}
                        {loaderState === false && fraudData.length === 0 ?
                            <div className="text-center">
                               <img src={Nodata} width={'40%'}/>
                            </div>
                            : ""}

                    </div>


                </div>
            </div>
        </>
    )
}
const mapStateToProps = ({ internalDedupeData }) => {
    return {
        internalDedupeData: internalDedupeData,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        newsimilarProfileRedux,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalDedupe)