import React from 'react'
import Nodata from '../../../../images/nodata.png'
import DatePicker from 'react-datepicker';
import { Pagination } from '@mui/material';
import InfoImage from './images/placeholder Fill (19).png'
import InfoInactive from './images/placeholder Fill (20).png'
import FraudImage from './images/placeholder Fill (21).png'
import FraudInactive from './images/placeholder Fill (22).png'
import moment from 'moment';

const Finbit = ({ zoomPage, zoomState, allDetail, jsondata, selectData, suffixDate, openCommentPopup, saveUnderWritter, underWriterHandler, setViewPage, viewPage, handleChangeNewPagination, pageNumber, fraudData, analysisData, bankDataFibit, datePickerState, finBitDataNew, typeHandler, viewFalse, type, finBitData, phocketTablePagination, tableMinPagination, tableMaxPagination, startDate, endDate, onChangeHandlerDate, searchHandler, finterChangeHandler,ConfirmCrifDataGenerate }) => (
    <>
        {zoomState ?
            <div className='container-fluid'>
                <div className="background-blur">
                    <div className='text-right' style={{
                    }}>
                        <i onClick={() => zoomPage('zoom')} style={{
                            fontSize: '14px', cursor: 'pointer', marginRight: '4.9%',
                            marginTop: '79px',
                            position: 'relative',
                            zIndex: 99,
                            height: '25px',
                            width: '25px',
                            background: 'red',
                            color: '#fff', padding: '4px 7px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'
                        }} class="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <div className="popup-main small-popup container  " style={{ padding: '20px', borderRadius: '10px', width: '100%', maxHeight: '100%', maxWidth: '90%', overflow: 'scroll', paddingBottom: '100px' }}>
                        <div className=' ' style={{ marginBottom: '20px' }}>
                            <>

                                <div className="row" style={{ marginTop: '30px' }} >
                                    <div className="col-sm-6 col-xs-12" style={{ marginTop: (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? '-40px' : '0px' }}>
                                        <h4 style={{ color: 'rgba(0, 0, 0, 0.80)' }}><i class="fa fa-server" aria-hidden="true"></i>&nbsp; &nbsp;Information Details</h4>
                                    </div>
                                </div>
                                <hr />
                                {analysisData && analysisData.finBitDecision !== undefined ?
                                    <div className="row " style={{ marginTop: '25px', marginBottom: '10px' }}>
                                        <div className='col-sm-6 col-xs-12'>
                                            {analysisData.finBitDecision === 'Approved' ?
                                                <div style={{ borderRadius: '6px', border: '1px solid var(--success, #00C851)', background: 'var(--light-green, #D7FBEA)', padding: '10px' }}>
                                                    <div className='row'>
                                                        <div className='col-sm-6 col-xs-6'>
                                                            <p style={{ marginTop: '15px' }}>Finbit Decision</p>
                                                        </div>
                                                        <div className='col-sm-6 col-xs-6 text-right'>
                                                            <h4><b style={{ color: analysisData.finBitDecision === 'Rejected' ? 'red' : analysisData.finBitDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finBitDecision}</b></h4>
                                                            {analysisData.fetchDate ? <> Fetched On - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div style={{ borderRadius: '6px', border: '1px solid var(--success, #F44)', background: 'var(--light-green, #FEECEB)', padding: '10px' }}>
                                                    <div className='row'>
                                                        <div className='col-sm-6 col-xs-6'>
                                                            <p style={{ marginTop: '15px' }}>Finbit Decision</p>
                                                        </div>
                                                        <div className='col-sm-6 col-xs-6 text-right'>
                                                            <h4><b style={{ color: analysisData.finBitDecision === 'Rejected' ? 'red' : analysisData.finBitDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finBitDecision}</b></h4>
                                                            {analysisData.fetchDate ? <>  Fetched On - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className='col-sm-6 col-xs-12'>
                                        </div>
                                    </div>
                                    : ""}
                                {analysisData !== '' && analysisData.rules !== '' && analysisData.rules !== null && analysisData.rules !== undefined && analysisData.rules.length > 0 ?
                                    <div className=' phocket-table-new text-center' >
                                        <table className="" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'center' }}>Rules </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {analysisData && analysisData.rules ? analysisData.rules.map((data, i) => {
                                                    return (
                                                        <tr key={i} >
                                                            <td>{data} </td>

                                                        </tr>
                                                    )
                                                }) : ""}

                                            </tbody>
                                        </table>
                                    </div>
                                    : ""}
                                {viewFalse ?
                                    <div>


                                        {datePickerState ?
                                            <div className='row' style={{ marginBottom: '20px' }}>
                                                <div className='col-sm-3 col-xs-12'>

                                                </div>
                                                <div className='col-sm-3 col-xs-12'>
                                                    <label style={{ fontSize: '12px' }}>Sart Date</label>
                                                    <DatePicker
                                                        value={startDate}
                                                        onChange={(e) => onChangeHandlerDate(e, 'startDate')}
                                                        className="form-control"
                                                        showYearDropdown
                                                        dateFormatCalendar="MMMM"
                                                        dateFormat="DD/MM/YYYY"
                                                        scrollableYearDropdown
                                                        yearDropdownItemNumber={50}
                                                    />
                                                </div>
                                                <div className='col-sm-3 col-xs-12'>
                                                    <label style={{ fontSize: '12px' }}>End Date</label>

                                                    <DatePicker
                                                        value={endDate}
                                                        onChange={(e) => onChangeHandlerDate(e, 'endDate')}
                                                        className="form-control"
                                                        showYearDropdown
                                                        dateFormatCalendar="MMMM"
                                                        dateFormat="DD/MM/YYYY"
                                                        scrollableYearDropdown
                                                        yearDropdownItemNumber={50}
                                                    />
                                                </div>
                                                <div className='col-sm-3 col-xs-12'>
                                                    <button className='btn btn default' style={{ background: '#00233C', color: '#fff', marginTop: '20px' }} onClick={searchHandler}>Search</button>
                                                </div>

                                            </div>
                                            : ""}
                                        <div className=' income'>

                                            <div className='container-fluid' style={{ background: '#F1F7FF', padding: '10px', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', border: '1px solid rgba(0, 0, 0, 0.25)' }} >
                                                <div className='col-sm-4 col-xs-12' >
                                                    <select className="form-control " onChange={(e) => typeHandler(e)} style={{ borderRadius: '5px', background: '#fff' }}>
                                                        <option value='transactionsDetail'>Transactions Detail</option>
                                                        <option value='averageMonthlyBalance'>Average Monthly Balance</option>
                                                        <option value='negativeBalanceTransactions'>Negative Balance Transactions</option>
                                                        <option value='income'>Income</option>
                                                        <option value='expenses'>Expenses</option>
                                                        <option value='creditCard'>CreditCard</option>
                                                        <option value='highValueTransactions'>HighValue Transactions</option>
                                                        <option value='moneyReceived'>Money Received</option>
                                                        <option value='moneyReceivedAnalysis'>Money Received Analysis</option>
                                                        <option value='moneyPaidAnalysis'>Money Paid Analysis</option>
                                                        {/* <option value='penaltyAnalysis'>Penalty Analysis</option> */}
                                                        <option value='loanRepayment'>Loan Repayment</option>
                                                        {/* <option value='averageUtilizationOcNCc'>Average Utilization OcNCc</option> */}
                                                        <option value='loanReceived'>loanReceived</option>
                                                        <option value='frequentTransaction'>Frequent Transaction</option>
                                                        {/* <option value='bounceTransactions'>Bounce Transactions</option> */}
                                                        {/* <option value='bounceTransactionsByDateRange'>Bounce Transactions By DateRange</option> */}
                                                        <option value='sanctionedAmount'>Sanctioned Amount</option>
                                                        <option value='ecsNNach'>EcsNNach</option>
                                                        <option value='creditCardPayment'>CreditCard Payment</option>
                                                        <option value='averageBankBalancePerMonth'>Average BankBalance Per Month</option>
                                                        <option value='monthSinceLastBounce'>Month Since Last Bounce</option>
                                                        <option value='checkInCompleteBankStatementDateDuration'>Check In Complete Bank Statement Date Duration</option>
                                                        <option value='summaryForSavingAccount'>Summary  for saving Account</option>

                                                        <option value='bankAccountSummary'>Bank Account Summary</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-xs-12 main-finbit-div' >
                                                <div className='row'>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >Account Details For Account List</label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined && bankDataFibit ? bankDataFibit.accountDetailsForAccountListPage : ""}</b></h5>
                                                    </div>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >Account Holder</label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountHolder : ""}</b></h5>
                                                    </div>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >Account Limit</label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountLimit : ""}</b></h5>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >Account Number</label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountNo : ""}</b></h5>
                                                    </div>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >Account Type</label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountType.name : ""}</b></h5>
                                                    </div>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >IFSC CODE</label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.ifsCode : ""}</b></h5>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >Address </label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.address : ""}</b></h5>
                                                    </div>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >Email</label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.email : ""}</b></h5>
                                                    </div>
                                                    <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                        <label className='' >Statement Upload</label>
                                                        <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.statementUpload : ""}</b></h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {type == 'summaryForSavingAccount' && fraudData ?
                                            <div className='row phocket-table-new credit-table' >
                                                <table className="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Rule Name</th>
                                                            <th>Status</th>
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {fraudData && fraudData.FraudAnalytics.result ? fraudData.FraudAnalytics.result.map((data, i) => {
                                                            return (
                                                                <tr key={i} style={{ background: data.status === 'FAIL' ? 'red' : '#fff', cursor: 'pointer' }} >
                                                                    <td>{data.ruleName} </td>
                                                                    <td>{data.status} </td>

                                                                </tr>
                                                            )
                                                        }) : ""}

                                                    </tbody>
                                                </table>
                                            </div>
                                            : ""}

                                        {/* <br />   <br /> */}
                                        {finBitDataNew !== '' ?
                                            <div className='row'  >
                                                {type == 'transactionsDetail' || type == 'income' || type == 'expenses' || type == 'creditCard' || type == 'highValueTransactions' || type == 'loanReceived' || type == 'frequentTransaction' || type == 'creditCardPayment' || type == 'ecsNNach' || type == 'summaryForSavingAccount' || type == 'moneyReceived' || type == 'loanRepayment' ?
                                                    <div className='col-sm-4 col-xs-12'>
                                                        <input style={{ borderRadius: '5px', background: '#fff', marginBottom: '10px', border: '1px solid' }} placeholder='Enter Text To Search In Description' className='form-control' onChange={(e) => finterChangeHandler(e)} />
                                                    </div>
                                                    : ""}
                                            </div>
                                            : ""}
                                        {finBitDataNew != '' ?
                                            <div className=" form-group overflow-auto phocket-table-new">

                                                <table className="" style={{ marginTop: '0px' }}>

                                                    <thead>
                                                        {type == 'transactionsDetail' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Type</th>
                                                                <th>Balance After Transaction</th>
                                                                <th>First Level Category</th>
                                                                <th>Second Level Category</th>

                                                            </tr>
                                                            : ""}
                                                        {type == 'income' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <td>Transaction Type</td>
                                                                <th>Balance After Transaction</th>
                                                                <th>Bank</th>
                                                                <th>Category</th>


                                                            </tr>
                                                            : ""}
                                                        {type == 'expenses' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Bank</th>
                                                                <th>Category</th>
                                                            </tr>
                                                            : ""}
                                                        {type == 'creditCard' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Bank</th>

                                                                <th>Total</th>
                                                            </tr>
                                                            : ""}
                                                        {type == 'highValueTransactions' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Type</th>

                                                                <th>Balance After Transcation</th>
                                                                <th>Bank</th>
                                                                <th>Category</th>

                                                            </tr>
                                                            : ""}
                                                        {(type == 'moneyReceivedAnalysis' || type == 'moneyPaidAnalysis') ?
                                                            <tr>
                                                                <th>Atm Withdraw</th>
                                                                <th>Cash</th>
                                                                <th>Cheque</th>
                                                                <th>DemandDraft</th>
                                                                <th>EcsAndNach</th>
                                                                <th>Imps</th>
                                                                <th>InterBank Transfer</th>
                                                                <th>Internal Amount</th>
                                                                <th>Online Transfer</th>
                                                                <th>Other</th>
                                                                <th>Rtgs</th>
                                                                <th>Total</th>
                                                                <th>Total Transactions</th>
                                                                <th>MonthAndYear</th>
                                                                <th>Neft</th>
                                                            </tr>
                                                            : ""}
                                                        {type == 'loanReceived' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Balance</th>
                                                                <th>Bank</th>

                                                            </tr>
                                                            : ""}
                                                        {type == 'frequentTransaction' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Bank</th>

                                                            </tr>
                                                            : ""}
                                                        {type == 'sanctionedAmount' ?
                                                            <tr>
                                                                <th>Bank</th>
                                                                <th>sanctionedAmount</th>
                                                            </tr>
                                                            : ""}
                                                        {type == 'ecsNNach' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>TransactionType</th>
                                                                <th>BalanceAfterTransaction</th>
                                                                <th>Bank</th>

                                                                <th>MonthAndYear</th>
                                                                <th>Total</th>

                                                            </tr> : ""}
                                                        {type == 'creditCardPayment' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Bank</th>

                                                                <th>MonthAndYear</th>
                                                                <th>Total</th>
                                                            </tr> : ""}
                                                        {type == 'averageBankBalancePerMonth' ?
                                                            <tr>
                                                                <th>Average Bank Balance Amount</th>
                                                                <th>Bank</th>
                                                                <th>FirstDayAmount</th>
                                                                <th>FourteenDayAmount</th>
                                                                <th>MonthAndYear</th>
                                                                <th>ThirtyDayAmount</th >
                                                            </tr > : ""}
                                                        {type == 'bounceTransactions' ?
                                                            <tr>
                                                                <th>InwardCheque Or EcsBounce Monthwise</th>
                                                                <th>OutwardCheque Bounce Monthwise</th>

                                                            </tr > : ""}
                                                        {type == 'loanRepayment' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Type</th>
                                                                <th>Balance After Transcation</th>
                                                                <th>Bank</th>
                                                                <th>Category</th>

                                                            </tr>
                                                            : ""}
                                                        {type == 'moneyReceived' ?

                                                            <tr >
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Transaction Type</th>
                                                                <th>Balance After Transcation</th>
                                                                <th>Bank</th>
                                                                <th>Category</th>
                                                                <th>MonthAndYear</th>
                                                                <th>Total</th>



                                                            </tr>
                                                            : ""}
                                                        {type == 'summaryForSavingAccount' ?
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Description</th>
                                                                <th>Amount</th>
                                                                <th>Transaction Type</th>
                                                                <th>Balance After Transaction</th>

                                                                <th>Bank</th>
                                                                <th>Category</th>

                                                            </tr>
                                                            : ""}
                                                    </thead >

                                                    <tbody>
                                                        {type === 'transactionsDetail' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            //                          var result = Object.keys(detail).map((key) => detail[key]);


                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.dateTime}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>

                                                                    <td>{detail.balanceAfterTransaction}</td>

                                                                    <td>{detail.firstLevelCategory}</td>
                                                                    <td>{detail.secondLevelCategory}</td>

                                                                </tr>
                                                            )
                                                        })
                                                            : null}


                                                        {type == 'income' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>

                                                                    <td>{detail.balanceAfterTransaction}</td>

                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.category}</td>

                                                                    {/* <td>{detail.transactionType}</td> */}

                                                                </tr>
                                                            )
                                                        })
                                                            : null}



                                                        {type == 'expenses' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.category}</td>



                                                                </tr>
                                                            )
                                                        })
                                                            : ""}
                                                        {type == 'creditCard' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td>{detail.bank}</td>

                                                                    <td>{detail.total}</td>


                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {type == 'highValueTransactions' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>
                                                                    <td>{detail.balanceAfterTranscation}</td>
                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.category}</td>

                                                                    {/* <td>{detail.type}</td> */}




                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {type == 'loanRepayment' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.dateTime}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                                                    <td>{detail.balanceAfterTransaction}</td>
                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.category}</td>

                                                                    {/* <td>{detail.transactionType}</td> */}




                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {(type == 'moneyReceivedAnalysis' || type == 'moneyPaidAnalysis') && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.atmWithdraw}</td>
                                                                    <td>{detail.cash}</td>
                                                                    <td>{detail.cheque}</td>
                                                                    <td>{detail.demandDraft}</td>
                                                                    <td>{detail.ecsAndNach}</td>
                                                                    <td>{detail.imps}</td>
                                                                    <td>{detail.interBankTransfer}</td>
                                                                    <td>{detail.internalAmount}</td>
                                                                    <td>{detail.onlineTransfer}</td>
                                                                    <td>{detail.other}</td>
                                                                    <td>{detail.rtgs}</td>
                                                                    <td>{detail.total}</td>
                                                                    <td>{detail.totalTransactions}</td>
                                                                    <td>{detail.monthAndYear}</td>
                                                                    <td>{detail.neft}</td>



                                                                </tr>
                                                            )
                                                        })
                                                            : null}

                                                        {type == 'moneyReceived' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                                                    <td>{detail.balanceAfterTransaction}</td>
                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.category}</td>
                                                                    <td>{detail.monthAndYear}</td>
                                                                    <td>{detail.total}</td>

                                                                    {/* <td>{detail.transactionType}</td> */}


                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {type == 'loanReceived' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td>{detail.balance}</td>
                                                                    <td>{detail.bank}</td>



                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {type == 'frequentTransaction' && finBitDataNew != null ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td>{detail.bank}</td>



                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {type == 'sanctionedAmount' && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>

                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.sanctionedAmount}</td>


                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {type == 'ecsNNach' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                                                    <td>{detail.balanceAfterTransaction}</td>
                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.monthAndYear}</td>
                                                                    <td>{detail.total}</td>


                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {type == 'creditCardPayment' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.date}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.monthAndYear}</td>
                                                                    <td>{detail.total}</td>


                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                        {type == 'averageBankBalancePerMonth' && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.averageBankBalanceAmount}</td>
                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.firstDayAmount}</td>
                                                                    <td>{detail.fourteenDayAmount}</td>
                                                                    <td>{detail.monthAndYear}</td>
                                                                    <td>{detail.thirtyDayAmount}</td>


                                                                </tr>
                                                            )
                                                        })
                                                            : null}



                                                        {type == 'summaryForSavingAccount' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                            const messageType = detail.description;
                                                            const amount = detail.amount;

                                                            const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                            //                          var result = Object.keys(detail).map((key) => detail[key]);


                                                            return (
                                                                <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                    <td>{detail.dateTime}</td>
                                                                    <td>{detail.description}</td>
                                                                    <td>{detail.amount}</td>
                                                                    <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>

                                                                    <td>{detail.balanceAfterTransaction}</td>

                                                                    <td>{detail.bank}</td>
                                                                    <td>{detail.category}</td>
                                                                    {/* <td>{detail.type}</td> */}


                                                                </tr>
                                                            )
                                                        })
                                                            : null}
                                                    </tbody>
                                                </table >
                                            </div >
                                            : ""}
                                        {type === 'transactionsDetail' && finBitData != null && finBitDataNew !== '' ?
                                            <div style={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
                                                <Pagination onChange={handleChangeNewPagination} page={pageNumber}
                                                    color="primary"
                                                    count={Math.ceil(finBitDataNew.length / 20)} />
                                            </div>
                                            // <div >

                                            //     <div className="container-fluid">
                                            //         <div className="row">
                                            //             <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block   " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'back')}></button></div>
                                            //             <div className="col-sm-2 col-xs-12  phoc text-center" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of '} {finBitDataNew.length}</div>
                                            //             <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'forward')}></button></div>
                                            //         </div>
                                            //     </div>
                                            // </div> 
                                            : ""}
                                        {(type !== 'transactionsDetail') && (finBitDataNew !== '' ? finBitDataNew.length > 20 : finBitData ? finBitData.length > 20 : '') ?
                                            <div >
                                                <div style={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
                                                    <Pagination onChange={handleChangeNewPagination} page={pageNumber}
                                                        color="primary"
                                                        count={Math.ceil(finBitDataNew.length / 20)} />
                                                </div>

                                            </div> : ""
                                        }
                                    </div>
                                    : <h3 style={{ marginTop: '40px' }} className="text-center"><img src={Nodata} width="300px" /></h3>}
                            </>
                        </div>
                    </div>
                </div>
            </div>
            : ""}

        <div className="container-fluid details-body-credit full-height credit-module">
            <div className="row" >
                <div className="col-sm-3 col-xs-12">
                    <div className="credit-blue-box">
                        <div className="row">
                            <div className="col-sm-3 col-xs-12">Result</div>
                            <div className="col-sm-9 col-xs-12 text-right"><b style={{ color: analysisData.finBitDecision === 'Rejected' ? 'red' : analysisData.finBitDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finBitDecision}</b></div>
                        </div>
                        <hr style={{ borderTop: '2px solid #A8C8FF' }} />
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">Decision <br />
                            </div>

                            <div className="col-sm-6 col-xs-12 text-right">{analysisData.finalDecision}
                                {analysisData.fetchDate ? <div style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.40' }}>Fetched On - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</div> : ""}
                            </div>
                        </div>
                        <hr style={{ borderTop: '2px solid #A8C8FF' }} />
                        <div className="row">
                            <div className="col-sm-6 col-xs-12">Salary Day</div>
                            <div className="col-sm-6 col-xs-12 text-right">{suffixDate}&nbsp;{suffixDate ? 'day of every Month' : ''}</div>
                        </div>
                        {/* <hr style={{ borderTop: '2px solid #A8C8FF' }} />
                <div className="row">
                    <div className="col-sm-6 col-xs-12">Source</div>
                    <div className="col-sm-6 col-xs-12 text-right">Net Banking</div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-6 col-xs-12">Time Period</div>
                    <div className="col-sm-6 col-xs-12 text-right">latest 3 Months</div>
                </div> */}
                    </div>
                    <div className='row' style={{ cursor: 'pointer' }} onClick={() => setViewPage('info')}>
                        <div className='col-sm-3 col-xs-3 text-right'>
                            <img src={viewPage === 'info' ? InfoImage : InfoInactive} width={'30px'} />
                        </div>
                        <div className='col-sm-9 col-xs-9'>
                            <div style={{ marginTop: '5px', color: viewPage === 'info' ? '#267DFF' : 'rgba(0, 0, 0, 0.40)' }}>Information Details</div>
                        </div>
                    </div>
                    <div className='row' style={{ cursor: 'pointer', marginTop: '20px' }} onClick={() => setViewPage('fraud')}>
                        <div className='col-sm-3 col-xs-3 text-right'>
                            <img src={viewPage === 'fraud' ? FraudImage : FraudInactive} width={'30px'} />
                        </div>
                        <div className='col-sm-9 col-xs-9'>
                            <div style={{ marginTop: '5px', color: viewPage === 'fraud' ? '#267DFF' : 'rgba(0, 0, 0, 0.40)' }}>Fraud Records</div>
                            {analysisData.fraudAnalyticsTransactionCount ? <div style={{ color: '' }}>Counts : {analysisData.fraudAnalyticsTransactionCount}</div> : ""}
                            {analysisData.fraudAnalyticsTransactionCount > 0 ? <div style={{ color: '#F44' }}>Suspicious File Input</div> : <div style={{ color: 'green' }}>Not Found</div>}

                            {analysisData.fetchDate ? <div style={{ fontSize: '10px' }}>Fetched on - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</div> : ""}

                        </div>
                    </div>
                    <div className='row' style={{ cursor: 'pointer', marginTop: '20px', marginBottom: '20PX' }} onClick={() => setViewPage('Bounces')}>
                        <div className='col-sm-3 col-xs-3 text-right'>
                            <img src={viewPage === 'Bounces' ? FraudImage : FraudInactive} width={'30px'} />
                        </div>
                        <div className='col-sm-9 col-xs-9'>
                            <div style={{ marginTop: '5px', color: viewPage === 'Bounces' ? '#267DFF' : 'rgba(0, 0, 0, 0.40)' }}>Bounces</div>
                            {analysisData.bounceTransactionsCount ? <div style={{ color: '' }}>Counts : {analysisData.bounceTransactionsCount}</div> : ""}
                            {analysisData.bounceTransactionsCount > 0 ? <div style={{ color: '#F44' }}>Suspicious File Input</div> : <div style={{ color: 'green' }}>Not Found</div>}
                            {analysisData.fetchDate ? <div style={{ fontSize: '10px' }}>Fetched on - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</div> : ""}
                        </div>
                    </div>
                </div>
                <div className="col-sm-9 col-xs-12 " style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.25)', height: '600px', overflow: 'auto' }}>
                    {(allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ?

                        <div className="">
                            <div className="col-sm-6 col-xs-6" style={{ marginTop: '10px' }}>
                            {bankDataFibit?.decision === 'Approve' ?
                                        <div style={{ borderRadius: '6px', border: '1px solid var(--success, #00C851)', background: 'var(--light-green, #D7FBEA)', padding: '10px' }}>
                                            {/* <h6 style={{ marginLeft: '60%' }}> {bankDataFibit?.verifedOn ? <>  Fetched On - {datefunction(bankDataFibit?.verifedOn)}</> : ""}</h6> */}
                                            <div className='row'>
                                                <div className='col-sm-6 col-xs-6'>
                                                    <h4 style={{ marginTop: '15px', fontSize: '14px' }}>Analysis Decision</h4>
                                                    <h4 style={{ marginTop: '15px', fontSize: '14px' }}>Score</h4>
                                                </div>
                                                <div className='col-sm-6 col-xs-6 text-right'>
                                                    <h4><b style={{ color: bankDataFibit?.decision === 'Rejected' ? 'red' : bankDataFibit?.decision === 'Approve' ? 'green' : '#31311f' }}>{bankDataFibit?.decision}</b></h4>
                                                    {bankDataFibit?.score ? bankDataFibit?.score : ""}

                                                </div>
                                            </div>
                                            <h6 style={{}}> {bankDataFibit?.verifedOn ? <>  Fetched On - {datefunction(bankDataFibit?.verifedOn)}</> : ""}</h6>

                                        </div>
                                        : <div style={{ borderRadius: '6px', border: '1px solid var(--success, #F44)', background: 'var(--light-green, #FEECEB)', padding: '10px' }}>
                                            <div className='row'>
                                                <div className='col-sm-6 col-xs-6'>
                                                    <h4 style={{ marginTop: '15px', fontSize: '14px' }}>Analysis Decision</h4>
                                                    <h4 style={{ marginTop: '15px', fontSize: '14px' }}>Score</h4>
                                                </div>
                                                <div className='col-sm-6 col-xs-6 text-right'>
                                                    <h4><b style={{ color: bankDataFibit?.decision === 'Rejected' ? 'red' : bankDataFibit?.decision === 'Approve' ? 'green' : '#31311f' }}>{bankDataFibit?.decision}</b></h4>
                                                    {bankDataFibit?.score ? bankDataFibit?.score : ""}

                                                </div>
                                            </div>
                                            <h6 style={{}}> {bankDataFibit?.verifedOn ? <>  Fetched On - {datefunction(bankDataFibit?.verifedOn)}</> : ""}</h6>

                                        </div>}
                            </div>
                            <div className="col-sm-6 col-xs-6" style={{ marginTop: '10px' }}>
                                <div style={{ float: 'inline-end' }} >
                                    <span onClick={() => openCommentPopup()} style={{ color: '#267DFF', fontWeight: '700', cursor: 'pointer' }}><i className='fa fa-commenting-o'></i>&nbsp;&nbsp;Comment&nbsp;&nbsp;</span>

                                    <select className="" style={{ padding: '10px', border: '1px solid #D3D9E9', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onChange={(e) => underWriterHandler(e, 'financialDecision')} >
                                        <option value={''}>Select Option</option>
                                        <option value={'Legal SMS Not Found'}>Legal SMS Not Found</option>
                                        <option value={'Suspicious/ Obligation Not Found'}>Suspicious/ Obligation Not Found</option>

                                    </select>
                                    <button className="btn btn-primary" style={{ marginTop: '6px', background: '#2B78FF', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }} onClick={() => saveUnderWritter('financialDecision')}>Save</button>
                                </div>
                                <div style={{textAlign:'end'}}>
                                <button className='reset-btn' onClick={() => zoomPage('zoom')}>
                                            Full Page view&nbsp; <i style={{ cursor: 'pointer', fontSize: '12px' }} className='fa fa-expand'></i>
                                        </button>&nbsp;&nbsp;
                                    <button className="reset-btn" style={{ marginTop: '0' }} onClick={() => ConfirmCrifDataGenerate('yes')}>Regenerate Details</button>
                                        </div>
                            </div>

                        </div>
                        : ""}

                    {viewPage === 'info' ?
                        <>

                            {/* <div className="row" >
                                <div className="col-sm-6 col-xs-12" style={{ marginTop: (allDetail.statusId === '101' || allDetail.statusId === '102' || allDetail.statusId === '103' || allDetail.statusId === '105' || allDetail.statusId === '113' || allDetail.statusId === '117' || allDetail.statusId === '110') ? '-40px' : '0px' }}>
                                    <h4 style={{ color: 'rgba(0, 0, 0, 0.80)' }}><i class="fa fa-server" aria-hidden="true"></i>&nbsp; &nbsp;Information Details</h4>
                                </div>
                            </div>
                            <hr /> */}
                            {analysisData && analysisData.finBitDecision !== undefined ?
                                <div className="row " style={{ marginTop: '25px', marginBottom: '10px' }}>
                                    <div className='col-sm-6 col-xs-12'>
                                        {/* {analysisData.finBitDecision === 'Approved' ?
                                            <div style={{ borderRadius: '6px', border: '1px solid var(--success, #00C851)', background: 'var(--light-green, #D7FBEA)', padding: '10px' }}>
                                                <div className='row'>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        <p style={{ marginTop: '15px' }}>Finbit Decision</p>
                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <h4><b style={{ color: analysisData.finBitDecision === 'Rejected' ? 'red' : analysisData.finBitDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finBitDecision}</b></h4>
                                                        {analysisData.fetchDate ? <> Fetched On - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            : <div style={{ borderRadius: '6px', border: '1px solid var(--success, #F44)', background: 'var(--light-green, #FEECEB)', padding: '10px' }}>
                                                <div className='row'>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        <p style={{ marginTop: '15px' }}>Finbit Decision</p>
                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <h4><b style={{ color: analysisData.finBitDecision === 'Rejected' ? 'red' : analysisData.finBitDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finBitDecision}</b></h4>
                                                        {analysisData.fetchDate ? <>  Fetched On - {analysisData.fetchDate ? moment(analysisData.fetchDate).format('DD-MM-YYYY') : ''}</> : ""}
                                                    </div>
                                                </div>
                                            </div>} */}
                                    </div>
                                    {/* <div className='col-sm-6 col-xs-12 text-right'>
                                        <button className='btn btn-primary' onClick={() => zoomPage('zoom')}>
                                            Full Page view&nbsp; <i style={{ cursor: 'pointer', fontSize: '20px' }} className='fa fa-expand'></i>
                                        </button>
                                    </div> */}
                                    {/* <div className='col-sm-6 col-xs-12'>
                            <p>Finbit Decision</p><b style={{ color: analysisData.finBitDecision === 'Rejected' ? 'red' : analysisData.finBitDecision === 'Approved' ? 'green' : '#31311f' }}>{analysisData.finBitDecision}</b>
                        </div> */}
                                    {/* <h3 style={{ textDecorationLine: 'underline' }} className="text-center blue-text">Finbit Analysis</h3> */}
                                </div>
                                : ""}
                            {analysisData !== '' && analysisData.rules !== '' && analysisData.rules !== null && analysisData.rules !== undefined && analysisData.rules.length > 0 ?
                                <div className=' phocket-table-new text-center' >
                                    <table className="" style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }}>Rules </th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {analysisData && analysisData.rules ? analysisData.rules.map((data, i) => {
                                                return (
                                                    <tr key={i} >
                                                        <td>{data} </td>

                                                    </tr>
                                                )
                                            }) : ""}

                                        </tbody>
                                    </table>
                                </div>
                                : ""}
                            {viewFalse ?
                                <div>


                                    {datePickerState ?
                                        <div className='row' style={{ marginBottom: '20px' }}>
                                            <div className='col-sm-3 col-xs-12'>

                                            </div>
                                            <div className='col-sm-3 col-xs-12'>
                                                <label style={{ fontSize: '12px' }}>Sart Date</label>
                                                <DatePicker
                                                    value={startDate}
                                                    onChange={(e) => onChangeHandlerDate(e, 'startDate')}
                                                    className="form-control"
                                                    showYearDropdown
                                                    dateFormatCalendar="MMMM"
                                                    dateFormat="DD/MM/YYYY"
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                />
                                            </div>
                                            <div className='col-sm-3 col-xs-12'>
                                                <label style={{ fontSize: '12px' }}>End Date</label>

                                                <DatePicker
                                                    value={endDate}
                                                    onChange={(e) => onChangeHandlerDate(e, 'endDate')}
                                                    className="form-control"
                                                    showYearDropdown
                                                    dateFormatCalendar="MMMM"
                                                    dateFormat="DD/MM/YYYY"
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={50}
                                                />
                                            </div>
                                            <div className='col-sm-3 col-xs-12'>
                                                <button className='btn btn default' style={{ background: '#00233C', color: '#fff', marginTop: '20px' }} onClick={searchHandler}>Search</button>
                                            </div>

                                        </div>
                                        : ""}
                                    <div className=' income'>

                                        <div className='container-fluid' style={{ background: '#F1F7FF', padding: '10px', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', border: '1px solid rgba(0, 0, 0, 0.25)' }} >
                                            <div className='col-sm-4 col-xs-12' >
                                                <select className="form-control " onChange={(e) => typeHandler(e)} style={{ borderRadius: '5px', background: '#fff' }}>
                                                    <option value='transactionsDetail'>Transactions Detail</option>
                                                    <option value='averageMonthlyBalance'>Average Monthly Balance</option>
                                                    <option value='negativeBalanceTransactions'>Negative Balance Transactions</option>
                                                    <option value='income'>Income</option>
                                                    <option value='expenses'>Expenses</option>
                                                    <option value='creditCard'>CreditCard</option>
                                                    <option value='highValueTransactions'>HighValue Transactions</option>
                                                    <option value='moneyReceived'>Money Received</option>
                                                    <option value='moneyReceivedAnalysis'>Money Received Analysis</option>
                                                    <option value='moneyPaidAnalysis'>Money Paid Analysis</option>
                                                    {/* <option value='penaltyAnalysis'>Penalty Analysis</option> */}
                                                    <option value='loanRepayment'>Loan Repayment</option>
                                                    {/* <option value='averageUtilizationOcNCc'>Average Utilization OcNCc</option> */}
                                                    <option value='loanReceived'>loanReceived</option>
                                                    <option value='frequentTransaction'>Frequent Transaction</option>
                                                    {/* <option value='bounceTransactions'>Bounce Transactions</option> */}
                                                    {/* <option value='bounceTransactionsByDateRange'>Bounce Transactions By DateRange</option> */}
                                                    <option value='sanctionedAmount'>Sanctioned Amount</option>
                                                    <option value='ecsNNach'>EcsNNach</option>
                                                    <option value='creditCardPayment'>CreditCard Payment</option>
                                                    <option value='averageBankBalancePerMonth'>Average BankBalance Per Month</option>
                                                    <option value='monthSinceLastBounce'>Month Since Last Bounce</option>
                                                    <option value='checkInCompleteBankStatementDateDuration'>Check In Complete Bank Statement Date Duration</option>
                                                    <option value='summaryForSavingAccount'>Summary  for saving Account</option>

                                                    <option value='bankAccountSummary'>Bank Account Summary</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-xs-12 main-finbit-div' >
                                            <div className='row'>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >Account Details For Account List</label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined && bankDataFibit ? bankDataFibit.accountDetailsForAccountListPage : ""}</b></h5>
                                                </div>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >Account Holder</label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountHolder : ""}</b></h5>
                                                </div>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >Account Limit</label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountLimit : ""}</b></h5>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >Account Number</label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountNo : ""}</b></h5>
                                                </div>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >Account Type</label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.accountType.name : ""}</b></h5>
                                                </div>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >IFSC CODE</label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.ifsCode : ""}</b></h5>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >Address </label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.address : ""}</b></h5>
                                                </div>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >Email</label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.email : ""}</b></h5>
                                                </div>
                                                <div className='col-sm-4 col-xs-12' style={{ marginTop: '20px' }}>
                                                    <label className='' >Statement Upload</label>
                                                    <h5 ><b>{bankDataFibit != null && bankDataFibit != '' && bankDataFibit != undefined ? bankDataFibit.statementUpload : ""}</b></h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {type == 'summaryForSavingAccount' && fraudData ?
                                        <div className='row phocket-table-new credit-table' >
                                            <table className="" style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Rule Name</th>
                                                        <th>Status</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {fraudData && fraudData.FraudAnalytics.result ? fraudData.FraudAnalytics.result.map((data, i) => {
                                                        return (
                                                            <tr key={i} style={{ background: data.status === 'FAIL' ? 'red' : '#fff', cursor: 'pointer' }} >
                                                                <td>{data.ruleName} </td>
                                                                <td>{data.status} </td>

                                                            </tr>
                                                        )
                                                    }) : ""}

                                                </tbody>
                                            </table>
                                        </div>
                                        : ""}

                                    {/* <br />   <br /> */}
                                    {finBitDataNew !== '' ?
                                        <div className='row'  >
                                            {type == 'transactionsDetail' || type == 'income' || type == 'expenses' || type == 'creditCard' || type == 'highValueTransactions' || type == 'loanReceived' || type == 'frequentTransaction' || type == 'creditCardPayment' || type == 'ecsNNach' || type == 'summaryForSavingAccount' || type == 'moneyReceived' || type == 'loanRepayment' ?
                                                <div className='col-sm-4 col-xs-12'>
                                                    <input style={{ borderRadius: '5px', background: '#fff', marginBottom: '10px', border: '1px solid' }} placeholder='Enter Text To Search In Description' className='form-control' onChange={(e) => finterChangeHandler(e)} />
                                                </div>
                                                : ""}
                                        </div>
                                        : ""}
                                    {finBitDataNew != '' ?
                                        <div className=" form-group overflow-auto phocket-table-new">

                                            <table className="" style={{ marginTop: '0px' }}>

                                                <thead>
                                                    {type == 'transactionsDetail' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Type</th>
                                                            <th>Balance After Transaction</th>
                                                            <th>First Level Category</th>
                                                            <th>Second Level Category</th>

                                                        </tr>
                                                        : ""}
                                                    {type == 'income' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <td>Transaction Type</td>
                                                            <th>Balance After Transaction</th>
                                                            <th>Bank</th>
                                                            <th>Category</th>


                                                        </tr>
                                                        : ""}
                                                    {type == 'expenses' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Bank</th>
                                                            <th>Category</th>
                                                        </tr>
                                                        : ""}
                                                    {type == 'creditCard' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Bank</th>

                                                            <th>Total</th>
                                                        </tr>
                                                        : ""}
                                                    {type == 'highValueTransactions' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Type</th>

                                                            <th>Balance After Transcation</th>
                                                            <th>Bank</th>
                                                            <th>Category</th>

                                                        </tr>
                                                        : ""}
                                                    {(type == 'moneyReceivedAnalysis' || type == 'moneyPaidAnalysis') ?
                                                        <tr>
                                                            <th>Atm Withdraw</th>
                                                            <th>Cash</th>
                                                            <th>Cheque</th>
                                                            <th>DemandDraft</th>
                                                            <th>EcsAndNach</th>
                                                            <th>Imps</th>
                                                            <th>InterBank Transfer</th>
                                                            <th>Internal Amount</th>
                                                            <th>Online Transfer</th>
                                                            <th>Other</th>
                                                            <th>Rtgs</th>
                                                            <th>Total</th>
                                                            <th>Total Transactions</th>
                                                            <th>MonthAndYear</th>
                                                            <th>Neft</th>
                                                        </tr>
                                                        : ""}
                                                    {type == 'loanReceived' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Balance</th>
                                                            <th>Bank</th>

                                                        </tr>
                                                        : ""}
                                                    {type == 'frequentTransaction' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Bank</th>

                                                        </tr>
                                                        : ""}
                                                    {type == 'sanctionedAmount' ?
                                                        <tr>
                                                            <th>Bank</th>
                                                            <th>sanctionedAmount</th>
                                                        </tr>
                                                        : ""}
                                                    {type == 'ecsNNach' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>TransactionType</th>
                                                            <th>BalanceAfterTransaction</th>
                                                            <th>Bank</th>

                                                            <th>MonthAndYear</th>
                                                            <th>Total</th>

                                                        </tr> : ""}
                                                    {type == 'creditCardPayment' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Bank</th>

                                                            <th>MonthAndYear</th>
                                                            <th>Total</th>
                                                        </tr> : ""}
                                                    {type == 'averageBankBalancePerMonth' ?
                                                        <tr>
                                                            <th>Average Bank Balance Amount</th>
                                                            <th>Bank</th>
                                                            <th>FirstDayAmount</th>
                                                            <th>FourteenDayAmount</th>
                                                            <th>MonthAndYear</th>
                                                            <th>ThirtyDayAmount</th >
                                                        </tr > : ""}
                                                    {type == 'bounceTransactions' ?
                                                        <tr>
                                                            <th>InwardCheque Or EcsBounce Monthwise</th>
                                                            <th>OutwardCheque Bounce Monthwise</th>

                                                        </tr > : ""}
                                                    {type == 'loanRepayment' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Type</th>
                                                            <th>Balance After Transcation</th>
                                                            <th>Bank</th>
                                                            <th>Category</th>

                                                        </tr>
                                                        : ""}
                                                    {type == 'moneyReceived' ?

                                                        <tr >
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Transaction Type</th>
                                                            <th>Balance After Transcation</th>
                                                            <th>Bank</th>
                                                            <th>Category</th>
                                                            <th>MonthAndYear</th>
                                                            <th>Total</th>



                                                        </tr>
                                                        : ""}
                                                    {type == 'summaryForSavingAccount' ?
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Transaction Type</th>
                                                            <th>Balance After Transaction</th>

                                                            <th>Bank</th>
                                                            <th>Category</th>

                                                        </tr>
                                                        : ""}
                                                </thead >

                                                <tbody>
                                                    {type === 'transactionsDetail' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        //                          var result = Object.keys(detail).map((key) => detail[key]);


                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.dateTime}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>

                                                                <td>{detail.balanceAfterTransaction}</td>

                                                                <td>{detail.firstLevelCategory}</td>
                                                                <td>{detail.secondLevelCategory}</td>

                                                            </tr>
                                                        )
                                                    })
                                                        : null}


                                                    {type == 'income' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>

                                                                <td>{detail.balanceAfterTransaction}</td>

                                                                <td>{detail.bank}</td>
                                                                <td>{detail.category}</td>

                                                                {/* <td>{detail.transactionType}</td> */}

                                                            </tr>
                                                        )
                                                    })
                                                        : null}



                                                    {type == 'expenses' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td>{detail.bank}</td>
                                                                <td>{detail.category}</td>



                                                            </tr>
                                                        )
                                                    })
                                                        : ""}
                                                    {type == 'creditCard' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td>{detail.bank}</td>

                                                                <td>{detail.total}</td>


                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {type == 'highValueTransactions' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>
                                                                <td>{detail.balanceAfterTranscation}</td>
                                                                <td>{detail.bank}</td>
                                                                <td>{detail.category}</td>

                                                                {/* <td>{detail.type}</td> */}




                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {type == 'loanRepayment' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.dateTime}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                                                <td>{detail.balanceAfterTransaction}</td>
                                                                <td>{detail.bank}</td>
                                                                <td>{detail.category}</td>

                                                                {/* <td>{detail.transactionType}</td> */}




                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {(type == 'moneyReceivedAnalysis' || type == 'moneyPaidAnalysis') && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.atmWithdraw}</td>
                                                                <td>{detail.cash}</td>
                                                                <td>{detail.cheque}</td>
                                                                <td>{detail.demandDraft}</td>
                                                                <td>{detail.ecsAndNach}</td>
                                                                <td>{detail.imps}</td>
                                                                <td>{detail.interBankTransfer}</td>
                                                                <td>{detail.internalAmount}</td>
                                                                <td>{detail.onlineTransfer}</td>
                                                                <td>{detail.other}</td>
                                                                <td>{detail.rtgs}</td>
                                                                <td>{detail.total}</td>
                                                                <td>{detail.totalTransactions}</td>
                                                                <td>{detail.monthAndYear}</td>
                                                                <td>{detail.neft}</td>



                                                            </tr>
                                                        )
                                                    })
                                                        : null}

                                                    {type == 'moneyReceived' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                                                <td>{detail.balanceAfterTransaction}</td>
                                                                <td>{detail.bank}</td>
                                                                <td>{detail.category}</td>
                                                                <td>{detail.monthAndYear}</td>
                                                                <td>{detail.total}</td>

                                                                {/* <td>{detail.transactionType}</td> */}


                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {type == 'loanReceived' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td>{detail.balance}</td>
                                                                <td>{detail.bank}</td>



                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {type == 'frequentTransaction' && finBitDataNew != null ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td>{detail.bank}</td>



                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {type == 'sanctionedAmount' && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>

                                                                <td>{detail.bank}</td>
                                                                <td>{detail.sanctionedAmount}</td>


                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {type == 'ecsNNach' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.transactionType}</td>
                                                                <td>{detail.balanceAfterTransaction}</td>
                                                                <td>{detail.bank}</td>
                                                                <td>{detail.monthAndYear}</td>
                                                                <td>{detail.total}</td>


                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {type == 'creditCardPayment' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined && finBitDataNew.length > 0 ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.date}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td>{detail.bank}</td>
                                                                <td>{detail.monthAndYear}</td>
                                                                <td>{detail.total}</td>


                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                    {type == 'averageBankBalancePerMonth' && finBitData != null && finBitData != '' && finBitData != undefined && finBitData.length > 0 ? finBitData.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.averageBankBalanceAmount}</td>
                                                                <td>{detail.bank}</td>
                                                                <td>{detail.firstDayAmount}</td>
                                                                <td>{detail.fourteenDayAmount}</td>
                                                                <td>{detail.monthAndYear}</td>
                                                                <td>{detail.thirtyDayAmount}</td>


                                                            </tr>
                                                        )
                                                    })
                                                        : null}



                                                    {type == 'summaryForSavingAccount' && finBitDataNew != null && finBitDataNew != '' && finBitDataNew != undefined ? finBitDataNew.slice(tableMinPagination, tableMaxPagination).map((detail, i) => {
                                                        const messageType = detail.description;
                                                        const amount = detail.amount;

                                                        const isEmiIncluded = jsondata[type] && jsondata[type].some(item => (item.description === messageType) && (item.amount === amount));
                                                        //                          var result = Object.keys(detail).map((key) => detail[key]);


                                                        return (
                                                            <tr key={i} style={{ background: isEmiIncluded ? 'lightgreen' : 'white', cursor: 'pointer' }} onClick={() => selectData(type, detail)}>
                                                                <td>{detail.dateTime}</td>
                                                                <td>{detail.description}</td>
                                                                <td>{detail.amount}</td>
                                                                <td style={{ fontWeight: 'bold', color: detail.type === 'DEBIT' ? 'red' : 'green' }}>{detail.type}</td>

                                                                <td>{detail.balanceAfterTransaction}</td>

                                                                <td>{detail.bank}</td>
                                                                <td>{detail.category}</td>
                                                                {/* <td>{detail.type}</td> */}


                                                            </tr>
                                                        )
                                                    })
                                                        : null}
                                                </tbody>
                                            </table >
                                        </div >
                                        : ""}
                                    {type === 'transactionsDetail' && finBitData != null && finBitDataNew !== '' ?
                                        <div style={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
                                            <Pagination onChange={handleChangeNewPagination} page={pageNumber}
                                                color="primary"
                                                count={Math.ceil(finBitDataNew.length / 20)} />
                                        </div>
                                        // <div >

                                        //     <div className="container-fluid">
                                        //         <div className="row">
                                        //             <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block   " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'back')}></button></div>
                                        //             <div className="col-sm-2 col-xs-12  phoc text-center" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of '} {finBitDataNew.length}</div>
                                        //             <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'forward')}></button></div>
                                        //         </div>
                                        //     </div>
                                        // </div> 
                                        : ""}
                                    {(type !== 'transactionsDetail') && (finBitDataNew !== '' ? finBitDataNew.length > 20 : finBitData ? finBitData.length > 20 : '') ?
                                        <div >
                                            <div style={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
                                                <Pagination onChange={handleChangeNewPagination} page={pageNumber}
                                                    color="primary"
                                                    count={Math.ceil(finBitDataNew.length / 20)} />
                                            </div>
                                            {/* <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-5 col-xs-12 no-padding"><button type="button" className="btn btn-default fa fa-chevron-left btn-lg btn-block " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'back')}></button></div>
                                <div className="col-sm-2 col-xs-12  phoc text-center" >{(tableMinPagination + 1) + ' - ' + (tableMaxPagination) + ' of '} {finBitDataNew.length}</div>
                                <div className="col-sm-5 col-xs-12 no-padding" ><button type="button" className="btn btn-default fa fa-chevron-right btn-lg btn-block " style={{ background: '#BADBF9', borderRadius: '0' }} onClick={e => phocketTablePagination(finBitData.transactions !== undefined ? finBitData.transactions : finBitDataNew, 'forward')}></button></div>
                            </div>
                        </div> */}
                                        </div> : ""
                                    }
                                </div>
                                : <h3 style={{ marginTop: '40px' }} className="text-center"><img src={Nodata} width="300px" /></h3>}
                        </> : ""}
                    {viewPage === 'fraud' ?
                        <>
                            <div className="row" >
                                <div className="col-sm-6 col-xs-12" style={{ marginTop: '-40px' }}>
                                    <h4 style={{ color: 'rgba(0, 0, 0, 0.80)' }}><i class="fa fa-server" aria-hidden="true"></i>&nbsp; &nbsp;Fraud Record List</h4>
                                </div>
                            </div>
                            <hr />
                        </>
                        : ""}
                    {viewPage === 'Bounces' ?
                        <>
                            <div className="row" >
                                <div className="col-sm-6 col-xs-12" style={{ marginTop: '-40px' }}>
                                    <h4 style={{ color: 'rgba(0, 0, 0, 0.80)' }}><i class="fa fa-server" aria-hidden="true"></i>&nbsp; &nbsp;Bounce Record List</h4>
                                </div>
                            </div>
                            <hr />
                            {analysisData  && analysisData.bounceTransactions&& analysisData.bounceTransactions.length > 0 ?
                                <div className=" form-group overflow-auto phocket-table-new">

                                    <table className="" style={{ marginTop: '0px' }}>

                                        <thead>

                                            <tr>
                                                <th className='text-center'>Date</th>
                                                <th className='text-center'>Amount</th>
                                                <th className='text-center'>Balance</th>
                                                <th className='text-center'>Bank</th>
                                                <th className='text-center'>Category</th>
                                                <th className='text-center'>Description</th>
                                                <th className='text-center'>TransactionType</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {analysisData.bounceTransactions.map((data,i) => (
                                                <tr key={i}>
                                                    <td className='text-center'>{moment(data.date).format('DD-MM-YYYY')}</td>
                                                    <td className='text-center'>{data.amount}</td>
                                                    <td className='text-center'>{data.balance}</td>
                                                    <td className='text-center'>{data.bank}</td>
                                                    <td className='text-center'>{data.category}</td>
                                                    <td className='text-center'>{data.description}</td>
                                                    <td className='text-center' style={{color:data.transactionType==='CREDIT'?'green':'red'}}>{data.transactionType}</td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>


                                </div>
                                : ""}
                        </>
                        : ""}
                </div>
            </div>
        </div>
    </>
)
export default Finbit